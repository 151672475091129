import React, { useEffect, useState } from 'react';
import { Label } from '../../atoms/texts/label';
import { Progress } from '@/components/molecules/progress';

export default function PrintErrorMessage({ error, children, label, className, delay }, ...props) {
    const [visible, setVisible] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (delay && typeof delay === 'number') {
            const intervalTime = 10; // Intervalle de temps pour mettre à jour la progression
            const steps = delay / intervalTime; // Nombre de mises à jour de la progression
            let currentStep = 0;

            const timer = setInterval(() => {
                currentStep++;
                setProgress((currentStep / steps) * 100);

                if (currentStep >= steps) {
                    setVisible(false);
                    clearInterval(timer);
                }
            }, intervalTime);

            return () => clearInterval(timer); // Nettoyage de l'intervalle en cas de démontage du composant
        }
    }, [delay]);

    if (!visible) {
        return null; // Si le composant n'est plus visible ou s'il n'y a pas d'erreur, on ne rend rien
    }

    if (!error && !label) return

    // if (!!error.graphQLErrors && Array.isArray(error.graphQLErrors)) {
    //     // Si error est un tableau, on parcourt chaque élément et on affiche une div avec un Label par élément
    //     return (
    //         <div className={`flex flex-col space-y-2 ${className}`} {...props}>
    //             {error.graphQLErrors.map((err, index) => (
    //                 <div key={index} className={`flex flex-col p-secondDegree border border-error rounded-sm bg-error20`}>
    //                     <Label message={label}>{err.message}</Label>
    //                 </div>
    //             ))}
    //         </div>
    //     );
    // } else 
    // if (error.message) {
    //     // Si error.message est une string, on affiche ce message dans le Label
    //     return (
    //         <div className={`flex flex-col p-secondDegree border border-error rounded-sm bg-error20 ${className}`} {...props}>
    //             {children ? children : <Label message={label}>{error.message}</Label>}
    //         </div>
    //     );
    // } else if (error && !error.message) {
    //     // Si error mais pas error.message, on affiche ce message dans le Label
    //     return (
    //         <div className={`flex flex-col p-secondDegree border border-error rounded-sm bg-error20 ${className}`} {...props}>
    //             {children ? children : <Label message={label}>{error}</Label>}
    //         </div>
    //     );
    // }
    // return null; // Si error n'est ni un message ni un tableau, on ne rend rien
    function ProgressErrorDelay() {
        return (
            delay && typeof delay === 'number' && (
                <Progress value={progress} className="w-1/3 h-1 " color="bg-error" />
            )
        )
    }
    return (
        <div className={`w-full flex flex-col space-y-2 p-1 ${className}`} {...props}>
            {/* {!!error.graphQLErrors && Array.isArray(error.graphQLErrors) ? (
                error.graphQLErrors.map((err, index) => (
                    <div key={index} className={`flex items-center justify-between p-secondDegree border border-error rounded-sm bg-error20`}>
                        <Label message={label} >{err.message}</Label>
                        <ProgressErrorDelay />
                    </div>
                ))
            ) :  */}
            {
                error?.message ? (
                    <div className={`flex  p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
                        {children ? children : <Label message={label} >{error.message}</Label>}
                        <ProgressErrorDelay />


                    </div>
                ) : (
                    <div className={`flex  p-secondDegree items-center justify-between border border-error rounded-sm bg-error20 ${className}`} {...props}>
                        {children ? children : <Label message={label}>{error}</Label>}
                        <ProgressErrorDelay />


                    </div>
                )
            }

        </div>
    );
}
