import React, { useRef, useState, useEffect } from 'react';

import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { AddRounded, DirectionsCar } from '@mui/icons-material';

import FormNewLeasing from '@/components/macro-organisms/panels/panels-admin/newLeasing/FormNewLeasing';
import Action from '@/components/molecules/buttons/Action';
import { useStoreLeasingInvoice } from '@/services/admin-services/api-rest/useStoreLeasingInvoice';
import { useAddLeasingInvoice } from '@/services/admin-services/mutations/useAddLeasingInvoice';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';

export default function NewLeasingPanel({ global = true, refetchFromScratch = ()=>{} }) {
    const submitRef = useRef(null);
    const [openNewLeasing, setOpenNewLeasing] = useState(false);
    const [dataToSend,setDataToSend]=useState();
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const { storeLeasingInvoice, loading: storeLoading, error: storeError, data: storeData } = useStoreLeasingInvoice();

    const { addLeasingInvoice, loading, error, data } = useAddLeasingInvoice()

    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        setDataToSend(formData)
        try {
            // Réinitialiser les états
            setSubmissionError(null);
            setSubmissionSuccess(false);

            // Étape 1 : Envoyer le fichier et récupérer l'UUID
            const fileResponse = await formData.file && storeLeasingInvoice(formData.file, dataToSend?.consultantUuid);
            // const leasingInvoiceFileUuid = fileResponse?.uuid || storeData?.uuid;
            console.log("fileResponse :", fileResponse);
        } catch (err) {
            // Gestion des erreurs
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
    };
    useEffect(() => {
        console.log("use effect storeData", storeData)

        const sendLeasing = async (uuidFile) => {
            // Valider et formater les valeurs de formData
            const newLeasingInvoice = {
                leasingInvoiceFileUuid: uuidFile, // Ajouter l'UUID du fichier
                consultantUuid: dataToSend.consultantUuid,
                period: dataToSend.period,
                invoiceDate: dataToSend.invoiceDate,
                currency: dataToSend.currency,
                amountExcludingVat: parseFloat(dataToSend.amountExcludingVat), // Convertir en Float
                totalAmount: parseFloat(dataToSend.totalAmount), // Convertir en Float
                comment: dataToSend.comment,
                vatList: dataToSend.vatList
            };
            console.log("newLeasingInvoice:", newLeasingInvoice, storeData, dataToSend.vatList);

            // Étape 2 : Envoyer la mutation avec les variables formatées
            await addLeasingInvoice({
                variables: newLeasingInvoice, // Passer les variables correctement
            });
        }
        if (storeData) {
            dataToSend && sendLeasing(storeData.uuid)
        }

        storeError && setSubmissionError(storeError)
    }, [storeError, storeData]);

    useEffect(() => {
        if (data?.addLeasingInvoice?.uuid) {
            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        console.log("use effect data", data)
        error && setSubmissionError(error)

    }, [error, data]);


    const handleClose = (close) => {
        setSubmissionError(null);
        setSubmissionSuccess(null);
        setOpenNewLeasing(close);
    };
    return (
        <Sheet open={openNewLeasing} onOpenChange={handleClose}>
            <SheetTrigger className={global && `w-full`}>

                {global ? (
                    <Action

                        icon={<DirectionsCar />}
                        text="consultants.dashboard.actions.leasing"
                        initialVariant={openNewLeasing ? "active" : "default"}


                    />
                ) : (
                    <Button leftIcon={<AddRounded />} showLeftIcon text="consultants.leasing.addLeasing" />
                )}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={"consultants.leasing.addLeasing"}
                    subheader={"global.information.allFieldsMandatory"}
                />
                <SheetSlot className=" flex  relative">
                    <FormNewLeasing onSubmit={handleSubmit} submitRef={submitRef} global={global} submissionError={submissionError} submissionSuccess={submissionSuccess} />
                </SheetSlot>
                <SheetFooter
                    buttonOutlineText={"global.actions.clear"}
                    buttonDressedText={"global.actions.ConfirmNext"}
                    warning={"global.userBeNotified"}
                    onClickOutline={(e) => submitRef.current && submitRef.reset.resetForm()}
                    onClickDressed={() => submitRef.current && submitRef.current.click()}

                />
            </SheetContent>
        </Sheet>
    )
}