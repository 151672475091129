import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogDescription, DialogHeader } from '@/components/ui/dialog'; // Ajustez selon la bibliothèque que vous utilisez
import { Button } from '@/components/molecules/buttons/button';
import { Label } from '@/components/atoms/texts/label';
import moment from 'moment';
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle-group";
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import { useTranslation } from 'react-i18next';

const EventDialog = ({ open, setDialogOpen, onSelect, selectedSlot }) => {
    const [timeSelection, setTimeSelection] = useState('allDay');
    const [isMultipleDays, setIsMultipleDays] = useState(false);
    const { t, i18n } = useTranslation();

    const eventTypes = [
        { title: 'Arret maladie', bgColor: 'bg-chart6' },
        { title: 'Mission', bgColor: 'bg-chart1' },
        { title: 'Congés payés', bgColor: 'bg-chart3' },
        { title: 'Autres', bgColor: 'bg-chart7' }];

    useEffect(() => {
        if (selectedSlot) {
            const isMultipleDays = moment(selectedSlot.end).diff(moment(selectedSlot.start), 'days') >= 1;
            setIsMultipleDays(isMultipleDays);
            if (isMultipleDays) {
                setTimeSelection('allDay');
            }
        }
    }, [selectedSlot]);

    const handleTimeSelectionChange = (value) => {
        setTimeSelection(value);
    };

    const handleSelectEventType = (title, bgColor) => {
        let start = selectedSlot.start;
        let end = selectedSlot.end;
    
        if (timeSelection === 'morning') {
            start = moment(start).startOf('day').toDate();
            end = moment(start).hours(12).toDate();
        } else if (timeSelection === 'afternoon') {
            start = moment(start).hours(12).toDate();
            end = moment(start).endOf('day').toDate();
        }
    
        console.log('handleSelectEventType', { title, bgColor, start, end });
        onSelect(title, bgColor, start, end);
    };
    

    const formatDate = (date) => {
        return moment(date).locale(i18n.language).format('LL');
    };

    const formatDateTime = (date) => {
        return moment(date).locale(i18n.language).format('LLL');
    };

    return (
        <Dialog open={open} onOpenChange={setDialogOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        <Label size="body" fontWeight="bold" message="global.actions.select" />
                    </DialogTitle>
                    {selectedSlot && (
                        <DialogDescription>
                            <div>
                                <p>Date de début : {formatDateTime(selectedSlot.start)}</p>
                                <p>Date de fin : {isMultipleDays ? formatDate(selectedSlot.end) : formatDateTime(selectedSlot.end)}</p>
                            </div>
                        </DialogDescription>
                    )}
                </DialogHeader>
                <div className="flex flex-col gap-4 py-4">
                    <ToggleGroup type="single" value={timeSelection} onValueChange={handleTimeSelectionChange} className='w-full'>
                        <ToggleGroupItem value="allDay" aria-label="Journée entière">                        
                            Journée entière
                        </ToggleGroupItem>
                        {!isMultipleDays && (
                            <>
                                <ToggleGroupItem value="morning" aria-label="Matin">
                                    Matin
                                </ToggleGroupItem>
                                <ToggleGroupItem value="afternoon" aria-label="Après-midi">
                                    Après-midi
                                </ToggleGroupItem>
                            </>
                        )}
                    </ToggleGroup>
                    <Separator/>
                    <div className="flex flex-col space-y-2">
                        {eventTypes.map((type) => (
                            <Button className={`${type.bgColor}`} style="ghost" key={type.title} onClick={() => handleSelectEventType(type.title, type.bgColor)}>
                                {type.title}
                            </Button>
                        ))}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EventDialog;
