/**
 * @component EditConsultant
 *
 * The `EditConsultant` component manages the editing and creation process of a consultant profile. It handles multiple steps of form input, allows the user to update or add a new prospect, and provides options to navigate between steps, including creating or updating a CV.
 *
 * Key Features:
 * - Multi-step form for editing consultant details.
 * - Ability to handle form submission, validation, and server responses.
 * - Integration with the `CreateAndUpdateCV` component to manage CV creation and updates.
 * - Conditional rendering based on the steps of the form and actions taken.
 * - Uses translation and form validation utilities for a multilingual and robust experience.
 *
 * Props:
 * - @param {Function} openCvSection - Function to open or close the CV section.
 * - @param {Function} setEditing - Function to set the editing state.
 * - @param {Function} setIsOpen - Function to toggle the open state of the component.
 * - @param {Object} user - User object containing details to prefill the form.
 * - @param {Function} setUser - Function to update the user object.
 * - @param {Boolean} [showFourthStep=false] - Flag to show the fourth step of the form.
 * - @param {Function} setShowFourthStep - Function to control the visibility of the fourth step.
 * - @param {Function} setIsDoingAction - Function to set the action state.
 * - @param {Function} refetchAllData - Function to refetch all data after an action.
 * - @param {Function} refetch - Function to refetch specific data related to the user.
 *
 * Local State:
 * - `hideFirstStep`: Boolean to control the visibility of the first step.
 * - `hideSecondStep`: Boolean to control the visibility of the second step.
 * - `hideThirdStep`: Boolean to control the visibility of the third step.
 * - `isFirStepValid`: Boolean to indicate if the first step is valid.
 * - `isSecondStepValid`: Boolean to indicate if the second step is valid.
 * - `isThirdStepValid`: Boolean to indicate if the third step is valid.
 * - `skipIsClicked`: Boolean to indicate if the skip button was clicked.
 * - `openCVSectionForDraft`: Boolean to control the visibility of the CV section.
 * - `civilities`: Array of gender options for the form.
 * - `userAdded`: Object to store details of the newly added user.
 * - `isCvCompleted`: Object to track the completion status of different CV sections.
 * - `formData`: Object to store form input values.
 * - `erros`: Object to store error messages for form fields.
 * - `listOfInvalidInput`: Array of invalid input field names.
 *
 * Usage:
 * - This component is used for editing consultant profiles, including general information and cooptation details.
 * - It includes steps for inputting basic details, selecting a cooptant, and managing the CV.
 * - It integrates with external services to handle prospect data and update the cooptation details.
 *
 * Example:
 * ```jsx
 * <EditConsultant
 *   openCvSection={toggleCvSection}
 *   setEditing={setIsEditing}
 *   setIsOpen={setIsOpen}
 *   user={currentUser}
 *   setUser={updateUser}
 *   setShowFourthStep={setShowFourthStep}
 *   setIsDoingAction={setIsDoingAction}
 *   refetchAllData={refetchAllData}
 *   refetch={refetch}
 * />
 * ```
 *
 * Dependencies:
 * - @component CreateAndUpdateCV: Handles the creation and updating of CVs.
 * - @component Label: Displays section headers and labels.
 * - @component Step: Component for step indicators.
 * - @component Button: Provides action buttons like "Next", "Skip", and "Create CV".
 * - @component TextFieldInput: Component for form input fields.
 * - @component Select, SelectContent, SelectTrigger, SelectValue, SelectItem: Components for dropdown selections.
 * - @component ArrowForwardIcon and CheckIcon from `@mui/icons-material`: Icons for navigation and success indicators.
 * - @hook useConsultantData: Custom hook for fetching consultant data.
 * - @hook useAllProspects: Custom hook for fetching all prospects.
 * - @hook useAllUsers: Custom hook for fetching all users.
 * - @hook useAddProspect: Custom hook for adding a new prospect.
 * - @hook useUpdateCooptedBy: Custom hook for updating cooptation details.
 * - @utils transformToProspectData: Utility function for transforming form data into prospect data format.
 * - @utils validateFormData: Utility function for validating form data.
 * - @utils formatDate: Utility function for formatting dates.
 * - @utils deleteFromInvalidInputs: Utility function for handling invalid input fields.
 *
 * Functions:
 * - `handleChange(e, name)`: Handles input field changes and updates form data.
 * - `handleSubmit(e)`: Submits the form data, performs validation, and handles server responses.
 * - `handleSecondStep(valueSelected)`: Handles the selection of a cooptant and updates cooptation details.
 * - `setProperty(propertyName, value, setIsCvCompleted)`: Updates the CV completion status.
 * - `isAllTrue(obj)`: Checks if all properties in an object are true.
 * - `handleSkip()`: Skips the third step and moves to the fourth step.
 * - `handleSecondStepSkip()`: Skips the second step and hides it.
 * - `handleFirstStepClick()`: Toggles the visibility of the first step.
 * - `handleSecondStepClick()`: Toggles the visibility of the second step.
 * - `handleThirdStepClick()`: Toggles the visibility of the third step.
 * - `handleOpenCvSection()`: Opens the CV section or sets it for draft.
 *
 * @example
 * const transformToProspectData = (formData) => {
 *   return {
 *     lastname: formData.name,
 *     firstname: formData.firstName,
 *     gender: formData.gender,
 *     personalEmail: formData.personalEmail,
 *     birthdate: formData.dayOfBirth,
 *   };
 * };
 */

import { useEffect, useState } from "react";
import { Label } from "@/components/atoms/texts/label";
import Step from "@/components/molecules/buttons/Step";
import CheckIcon from "@mui/icons-material/Check";
import Input from "@/components/molecules/inputs/Input";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Button } from "@/components/molecules/buttons/button";
import UniqueSelect from "@/components/molecules/inputs/UniqueSelect";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import { Form, FormControl } from "@/components/organisms/forms/form";
import { validateFormData } from "./validateForm";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import useConsultantData from "@/components/organisms/navigation/MenuConsultant/useConsultantData";
import { getUsersName } from "@/utils/transformUserData";
import { cn } from "@/lib/utils";
import TextFieldInput from "@/components/molecules/inputs/TextFieldInput";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { deleteFromInvalidInputs } from "@/utils/formValidationUtils";
import { motion } from "framer-motion";
import CreateAndUpdateCV from "../CreateAndUpdateCV";
import { formatDate } from "@/utils/formatDate";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
// import Select from "@mui/material/Select";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,

  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { findUserByEmail } from "@/utils/transformUserData";
import MenuItem from "@mui/material/MenuItem";
import UniqueSelect2 from "@/components/molecules/inputs/UniqueSelect2";
import UniqueSelectMui from "@/components/molecules/inputs/UniqueSelectMui";
import { useAllProspects } from "@/services/admin-services/query/useAllProspects";
import { useTranslation } from "react-i18next";
import { InputLabel } from "@mui/material";

import { useAddProspect } from "@/services/admin-services/mutations/useAddProspect";
import { useAllUsers } from "@/services/admin-services/query/useAllUsersV2";
import { useUpdateCooptedBy } from "@/services/admin-services/mutations/useUpdateCooptedBy";
import Consultant from "@/pages/admin/consultant/consultant";

const genre = ["Masculin", "Féminin", "Autres"];
export default function EditConsultant({
  openCvSection,
  setEditing,
  setIsOpen,
  user,
  setUser,
  showFourthStep = false,
  setShowFourthStep,
  setIsDoingAction,
  refetchAllData,
refetch,
  ...props
}) {
  const { addProspect, loading, error, data: addedProspect } = useAddProspect();
  const {
    loading: loadingUsers,
    error: errorUsers,
    data,
    
  } = useAllUsers();
  const {
    updateCooptedBy,
    loading: loadingCooptedBy,
    error: errorCoopted,
  } = useUpdateCooptedBy();
  const [sliceNumber, setSliceNumber] = useState(1);

  const {
    loading: loadingAllProspects,
    error: errorAllProspects,
    data: dataAllProspects,
    refetch: refetchAllprospects,
  } = useAllProspects(sliceNumber);
  const [hideFirstStep, setHideFirstStep] = useState(false);
  const [hideSecondStep, setHideSecondStep] = useState(true);
  const [hideThirdStep, setHideThirdStep] = useState(true);
  let { users } = useConsultantData();
  users ? (users = getUsersName(users)) : "";
  const [selectedCooptant, setCooptant] = useState(null);
  const form = useForm();
  const [isFirStepValid, setIsFirsStepValid] = useState(false);
  const [isSecondStepValid, setIsSecondStepValid] = useState(false);
  const [isThirdStepValid, setIsThirdStepValid] = useState(false);
  const [skipIsClicked, setSkipIsClicked] = useState(false);
  const [openCVSectionForDraft, setOpenCvSectionForDraft] = useState(false);
  const [civilities, setCivilites] = useState([]);
  const [userAdded, setUserAdded] = useState(null);

  const [isCvCompleted, setIsCvCompleted] = useState({
    information: false,
    skills: false,
    description: false,
    certifications: false,
    experience: false,
    educations: false,
    projects: false,
  });

  const [formData, setFormData] = useState({
    name: user ? user.name : "",
    firstName: user ? user.firstName : "",
    personalEmail: user ? user.personalEmail : "",
    dayOfBirth: user ? user.dayOfBirth : "",
    gender: user ? user.gender : "",
  });

  const [erros, setErros] = useState({
    name: "",
    firstName: "",
    personalEmail: "",
    dayOfBirth: "",
    gender: "",
  });
  const [listOfInvalidInput, setlistOfInvalidInput] = useState([]);
  const [t] = useTranslation("global");

  useEffect(() => {
    let data = [];
    const male = t("consultants.statement.prospectConsultantPanel.male");
    const female = t("consultants.statement.prospectConsultantPanel.female");
    const other = t("consultants.statement.prospectConsultantPanel.other");

    data.push(male);
    data.push(female);
    data.push(other);
    setCivilites(data);
  }, [t]);

  // useEffect(() => {
  //   refetch(); // Refetch data when the component mounts
  // }, [refetch]);
  const handleChange = (e, name) => {
    const value = name != "gender" ? e.target.value : e;

    deleteFromInvalidInputs(name, setlistOfInvalidInput, listOfInvalidInput);
    console.log(value, e, name != "gender" ? e.target : e);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // setUser((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));

    // console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateFormData(formData, setErros);
    setlistOfInvalidInput(isValid);
    if (isValid.length == 0) {
      try {
        await addProspect(transformToProspectData(formData));

        setIsDoingAction && setIsDoingAction(true);
       refetchAllData && refetchAllData();
      } catch (err) {
        alert(err.message);
      }
      console.log("Form is valid", formData);
      setHideFirstStep(true);
      setIsFirsStepValid(true);
      setHideSecondStep(false);
      setEditing(true);
    } else {
      console.log("invalid form");
      console.log("value invalid", isValid);
      console.log("form data", formData);
    }
  };
  const handleSecondStep = async (valueSelected) => {
    try {
      // Mettre à jour le cooptant
      console.log("%%%%%%%zdgbrgbrbrt, user ", addedProspect);
      setUser(addedProspect.addProspect);

      await updateCooptedBy(addedProspect.addProspect.uuid, valueSelected.uuid);
      //alert("Coopted by updated successfully");
      setCooptant(valueSelected);
      setIsSecondStepValid(true);
      refetch && refetch();
      refetchAllData && refetchAllData()

      // Mettre à jour les états de la vue
    } catch (err) {
      alert(`Failed to update coopted by: ${err.message}`);
    }
  };
  const setProperty = (propertyName, value, setIsCvCompleted) => {
    setIsCvCompleted((prevState) => ({
      ...prevState,
      [propertyName]: value,
    }));
  };
  //   useEffect(() => {
  //    setUser && setUser(formData);
  // },[formData])
  // Fonction pour vérifier si toutes les propriétés booléennes sont vraies
  const isAllTrue = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        return false;
      }
    }
    return true;
  };
  const handleSkip = () => {
    // isThirdStepValid(false);
    setHideThirdStep(true);
    setSkipIsClicked(true);
    setShowFourthStep && setShowFourthStep(true);
  };
  const handleSecondStepSkip = async () => {
    setHideThirdStep(false);
    setHideSecondStep(true);
  };
  const handleFirstStepClick = () => {
    setHideFirstStep(!hideFirstStep);
    setHideSecondStep(true);
    setHideThirdStep(true);
  };
  const handleSecondStepClick = () => {
    setHideFirstStep(true);
    setHideSecondStep(!hideSecondStep);
    setHideThirdStep(true);
  };
  const handleThirdStepClick = () => {
    setHideThirdStep(false);
    setHideSecondStep(true);
    setHideFirstStep(true);
    setShowFourthStep && setShowFourthStep(false);
  };
  const handleOpenCvSection = () => {
    openCvSection ? openCvSection(true) : setOpenCvSectionForDraft(true);
  };

  return (
    <motion.div
      initial={{
        scale: 0.7,
      }}
      animate={{
        scale: 1,
      }}
      {...props}
    >
      {/* {isSecondStepValid && (
        <FormIsSend message={"success.changesSaved"} />
      )} */}

      {!openCVSectionForDraft && (
        <div className="flex flex-col gap-thirdDegree p-secondDegree">
          {/*first step*/}
          <motion.div
            initial={{
              scale: 0.9,
            }}
            animate={{
              scale: 1,
            }}
            className="flex gap-secondDegree"
          >
            <div className=" flex flex-col items-center ">
              <Step
                item={isFirStepValid ? <CheckIcon /> : "1"}
                bg={isFirStepValid ? "success" : "white"}
                borderColor={isFirStepValid && "success"}
                borderSize={isFirStepValid ? "solid" : "default"}
                onClick={handleFirstStepClick}
              />
              {!hideFirstStep && <Separator orientation="vertical" />}
            </div>
            <div className="flex flex-col w-full ">
              <Label
                message="consultants.statement.prospectConsultantPanel.generalInformation"
                size="body"
                fontWeight="bold"
              />
              <Label
                message={
                  hideFirstStep
                    ? "global.information.done"
                    : "global.information.mandatory"
                }
                size="caption"
                color="primary"
              />
              {!hideFirstStep && (
                <div>
                  <Form {...form}>
                    <form onSubmit={handleSubmit}>
                      <div className="py-secondDegree flex flex-col gap-secondDegree w-full">
                        {console.log(formData)}
                        <Select
                          value={formData.gender}
                          onValueChange={(e) => handleChange(e, "gender")}
                        >
                          <SelectTrigger className="rounded-[5px] bg-transparent border-greyStrong focus:ring-0 focus:ring-offset-0">
                            <SelectValue
                              placeholder={
                                formData.gender
                                  ? formData.gender
                                  : t("global.information.genre")
                              }
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="MALE">
                                <Label message="consultants.statement.prospectConsultantPanel.male"></Label>
                              </SelectItem>
                              <SelectItem value="FEMALE">
                                <Label message="consultants.statement.prospectConsultantPanel.female"></Label>
                              </SelectItem>
                              <SelectItem value="OTHER">
                                <Label message="consultants.statement.prospectConsultantPanel.other"></Label>
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <TextFieldInput
                          required
                          placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.firstName"
                          value={formData.firstName}
                          onChange={(e) => handleChange(e, "firstName")}
                          //name="firstName"
                          error={listOfInvalidInput.includes("firstName")}
                          type="text"
                        />

                        <TextFieldInput
                          placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.name"
                          value={formData.name}
                          onChange={(e) => handleChange(e, "name")}
                          required
                          //name="name"
                          type="text"
                          error={listOfInvalidInput.includes("name")}
                        />

                        <TextFieldInput
                          required
                          placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.dayOfBirth"
                          value={formData.dayOfBirth}
                          onChange={(e) => handleChange(e, "dayOfBirth")}
                          //name="firstName"
                          error={listOfInvalidInput.includes("dayOfBirth")}
                          type="date"
                        />

                        <TextFieldInput
                          placeHolder="consultants.statement.prospectConsultantPanel.formPlaceHolder.personalEmail"
                          // name="personalEmail"
                          value={formData.personalEmail}
                          required
                          onChange={(e) => handleChange(e, "personalEmail")}
                          type="email"
                          error={listOfInvalidInput.includes("personalEmail")}
                        />
                      </div>
                      <div className="py-secondDegree">
                        <Button
                          showText={true}
                          text="global.actions.next"
                          type="submit"
                          sizePadding="" // Utilisez "small" pour un padding plus petit
                          style="fill"
                          className="font-bold"
                          //onClick={() => { erros && setHideFirstStep(true) }} // Options: "fill", "outline", "ghost", "dressedGhost"
                        />
                      </div>
                    </form>
                  </Form>
                </div>
              )}
            </div>
          </motion.div>
          {/*second step*/}
          <motion.div
            initial={{
              scale: 0.8,
            }}
            animate={{
              scale: 1,
            }}
            className="flex gap-secondDegree"
          >
            <div className=" flex flex-col items-center ">
              <Step
                item={isSecondStepValid ? <CheckIcon /> : "2"}
                bg={isSecondStepValid ? "success" : "white"}
                borderColor={isSecondStepValid && "success"}
                borderSize={isSecondStepValid ? "solid" : "default"}
                onClick={handleSecondStepClick}
              />
              {!hideSecondStep && <Separator orientation="vertical" />}
            </div>
            <div className="flex flex-col w-full ">
              <Label
                message="consultants.statement.prospectConsultantPanel.cooptation"
                size="body"
                fontWeight="bold"
              />
              <Label
                message={
                  isSecondStepValid
                    ? "global.information.done"
                    : "consultants.statement.prospectConsultantPanel.canbeDoneLater"
                }
                size="caption"
                color="primary"
              />
              {!hideSecondStep && (
                <div className="w-full">
                  <div className=" w-full flex py-secondDegree">
                
                    <Select onValueChange={handleSecondStep}>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={
                            <Label message="global.actions.select" />
                          }
                        />
                      </SelectTrigger>

                      <SelectContent>
                        <>
                          {data.allUsers &&
                            data.allUsers.map((user) => (
                              <SelectItem value={user} key={user.uuid}>
                                <Label>
                                  {user.firstname +
                                    " " +
                                    user.lastname +
                                    " - " +
                                    user.trigram}
                                </Label>
                              </SelectItem>
                            ))}
                        </>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="py-secondDegree">
                    <Button
                      showText={true}
                      text="global.actions.next"
                      sizePadding="" // Utilisez "small" pour un padding plus petit
                      style="fill"
                      className="font-bold"
                      onClick={handleSecondStepSkip} // Options: "fill", "outline", "ghost", "dressedGhost"
                    />
                  </div>
                </div>
              )}
            </div>
          </motion.div>
          {/*third step*/}
          <motion.div
            initial={{
              scale: 0.6,
            }}
            animate={{
              scale: 1,
            }}
            className="flex gap-secondDegree"
          >
            <div className=" flex flex-col items-center ">
              <Step
                item={isThirdStepValid ? <CheckIcon /> : "3"}
                bg={isThirdStepValid ? "success" : "white"}
                borderColor={isThirdStepValid && "success"}
                borderSize={isThirdStepValid ? "solid" : "default"}
                onClick={handleThirdStepClick}
              />
              {!hideThirdStep && <Separator orientation="vertical" />}
            </div>
            <div className="flex flex-col w-full ">
              <Label
                message="consultants.statement.prospectConsultantPanel.createAndUpdateCV"
                size="body"
                fontWeight="bold"
              />
              <Label
                message={
                  isThirdStepValid
                    ? "global.information.done"
                    : "consultants.statement.prospectConsultantPanel.canbeDoneLater"
                }
                size="caption"
                color="primary"
              />
              {!hideThirdStep && !showFourthStep && (
                <div className="w-full">
                  <div className="py-secondDegree">
                    <div className=" w-full flex py-secondDegree">
                      <Label message="consultants.statement.prospectConsultantPanel.youCanAddAndetc" />
                    </div>
                    <div className="py-secondDegree gap-firstDegree flex">
                      <Button
                        showText={true}
                        text="global.actions.skip"
                        sizePadding="" // Utilisez "small" pour un padding plus petit
                        style="outline"
                        className="font-bold border-primary"
                        onClick={() => handleSkip()} // Options: "fill", "outline", "ghost", "dressedGhost"
                      />
                      <Button
                        showText={true}
                        showLeftIcon={true}
                        leftIcon={<ContactPageIcon fontSize="small" />}
                        showRightIcon={true}
                        rightIcon={<ArrowForwardIcon fontSize="small" />}
                        text="consultants.statement.prospectConsultantPanel.createCv"
                        sizePadding="" // Utilisez "small" pour un padding plus petit
                        style="fill"
                        className="font-bold"
                        onClick={handleOpenCvSection} // Options: "fill", "outline", "ghost", "dressedGhost"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
          {showFourthStep && (
            <motion.div
              initial={{
                scale: 0.3,
              }}
              animate={{
                scale: 1,
              }}
              className="flex gap-secondDegree"
            >
              <div className=" flex flex-col items-center ">
                <Step
                  item={<CheckIcon />}
                  bg={"success"}
                  borderColor={"success"}
                  borderSize={"solid"}
                  //onClick={() => setHideThirdStep(false)}
                />

                <Separator orientation="vertical" />
              </div>
              <div className="flex flex-col w-full ">
                <Label
                  message={"global.information.congrates"}
                  size="body"
                  fontWeight="bold"
                />

                <Label
                  message={"global.information.profilSaved"}
                  size="caption"
                  color="primary"
                />

                <div className="w-full">
                  <div className="py-secondDegree">
                
                    <div className="py-secondDegree gap-firstDegree flex">
                      <Button
                        showText={true}
                        text="global.actions.goToProfile"
                        sizePadding="" // Utilisez "small" pour un padding plus petit
                        style="outline"
                        className="font-bold border-primary"
                        // onClick={() => isThirdStepValid(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                      />
                      <Button
                        showText={true}
                        text="global.actions.close"
                        sizePadding="" // Utilisez "small" pour un padding plus petit
                        style="fill"
                        className="font-bold"
                        onClick={() => setIsOpen(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      )}
      {openCVSectionForDraft && (
        <CreateAndUpdateCV
          openCvSection={setOpenCvSectionForDraft}
          shoulGetSectionsData={true}
          setShowFourthStep={setShowFourthStep}
          user={user}
        />
      )}
    </motion.div>
  );
}
const transformToProspectData = (formData) => {
  return {
    lastname: formData.name,
    firstname: formData.firstName,
    gender: formData.gender,
    personalEmail: formData.personalEmail,
    birthdate: formData.dayOfBirth,
  };
};