import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import errorToText from '@/utils/errorToText';

const useUpdateCooptation = () => {

    const [uuidResponnse, setUuidResponnse] = useState(null);
    const { user: authUser } = useAuth();

    const setCooptation = async (uuidCoopting, cooptedBy) => {
        if (!authUser.strongToken) {
            setError('No strong token available');
            return { data: null, error: 'No strong token available' };
        }
       
       
        console.log("cooptedBy", cooptedBy);
        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authUser.strongToken}`
                },
                body: JSON.stringify({
                    query: `
                    mutation updateCooptations {
                        updateCooptations(
                          updatedCooptations: {
                            consultantUuid: "${uuidCoopting}", 
                            cooptedBy: ${JSON.stringify(cooptedBy).replace(/"([^"]+)":/g, '$1:')}
                            }
                        ) {
                          uuid
                        }
                      }
                    `
                })
            });
console.log("response", response, response.ok);
        				if (response.status == 500) {
                //gestion spécifique d'un cas d'erreur particulier
            }

            if (!response.ok) {
                const errMessage = errorToText(response);
                throw new Error(errMessage);
            }

            const data = await response.json();
            if (data.errors) {
                console.log(data);
                const errMessage = data.errors[0].message;
                throw new Error(errMessage);
            }
            console.log(data);

            setUuidResponnse(data.data.updateCooptations);

            return { data: data.data.updateCooptations, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    return { setCooptation, uuidResponnse };
};

export default useUpdateCooptation;