import React from 'react'
import { Label } from '../../atoms/texts/label';
export default function PrintSuccessMessage({success, isComponent=false, className}) {

  return (
    <div
      className={`flex flex-col p-secondDegree border border-success rounded-sm bg-success20 ${className}`}
    >
      {isComponent && success}
      {!isComponent && <Label message={success}>{success}</Label>}
    </div>
  );
}
