import { Label } from "@/components/atoms/texts/label";
import { useEffect, useState } from "react";

let errorNetworkVisible = false;

const setErrorNetworkVisible = (isError) => {
    errorNetworkVisible = isError;
    console.log("Graphql NetworkConnectionLost: setErrorNetworkVisible", isError, errorNetworkVisible);
    return (errorNetworkVisible);
}
function NetworkConnectionLost() {

    // const [error, setError] = useState(false);

    // // This effect will run once to initialize the setErrorNetworkVisible function
    // useEffect(() => {
    // console.log("Graphql NetworkConnectionLost: setErrorNetworkVisible", isError, errorNetworkVisible);
    // }, [errorNetworkVisible]);

    // console.log("Graphql NetworkConnectionLost", errorNetworkVisible);

    //TODO créer un ccontexte pour la perte de connexion
    if (errorNetworkVisible) return (
        <></>
        // <div className="flex items-center justify-center p-1 bg-error">
        //     <Label>Ohoh ! La connexion Internet a été perdue, reconnexion en cours ...</Label>
        // </div>
    )
}

export { NetworkConnectionLost, setErrorNetworkVisible }