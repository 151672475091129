import React, { useCallback } from 'react';
import NewTimesheetPanel from './NewTimesheetPanel';
import { useTimesheetData } from './useTimesheetData';
import Timesheet from './Timesheet';
import { Toaster } from '@/components/organisms/feedback/toaster';

export default function TimesheetTab() {
    const { timesheets, error } = useTimesheetData();



    return (

        <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">
            

            {(!error && timesheets) && timesheets.map((item, index) => (
                < Timesheet key={index} data={item} dataOwner={false} />
            ))}
            <Toaster />


        </div>
    );
}

