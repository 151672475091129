import { useState } from "react";
import { useAuth } from "@/context/AuthContext";

export const useStoreLeasingInvoice = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);


    const storeLeasingInvoice = async (file, consultantUuid) => {
        setLoading(true);
        setError(null);

        if (!file || !consultantUuid) return;

        const formData = new FormData();
        formData.append("leasingInvoice", file);
        formData.append("consultantUuid", consultantUuid);

        console.log("storeLeasingInvoice file", file);

        try {
            const response = await fetch(window.ENV.BACKEND_URL+"/api/secured/admin/leasing/leasingInvoiceFile", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to store leasing invoice");
            }

            const result = await response.json();
            setData(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { storeLeasingInvoice, loading, error, data };
};
