import React from 'react'
import { Label } from '../../atoms/texts/label';
export default function PrintWarningMessage({warning, className}) {
  return (
    <div className={`flex flex-col p-secondDegree border border-warning rounded-sm bg-warning20 ${className}`}>
      
      <Label message={ warning}>{warning}</Label>
    </div>
  );
}
