import React from "react";
import Action from "@/components/molecules/buttons/Action";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import PersonIcon from "@mui/icons-material/Person";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AddTaskIcon from "@mui/icons-material/AddTask";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useState, useEffect } from "react";

import DividerLight from "@/components/atoms/divider/dividerLight";

import InformationTabUser from "./information/InformationTabUser";
import Statement from "./statement/StatementTabUser";
import { Outlet, useNavigate } from "react-router-dom";
import CVTabUser from "./cv/cvTabUser";
import SkillsPageConsultant from "./skills/SkillTabUser";
import { urlMatches } from "@opentelemetry/core";

export default function DetailsTabs() {
  const navigate = useNavigate();
  const actions = [
    {
      icon: <ViewQuiltIcon />,
      text: "consultants.dashboard.tabs.statement",
      url: "./statement",
    },
    {
      icon: <PersonIcon />,
      text: "consultants.dashboard.tabs.information",
      url: "./information",
    },
    {
      icon: <ContactPageIcon />,
      text: "consultants.dashboard.tabs.cv",
      url: "./cv",
      variant:"disabled"
    },
    {
      icon: <RequestQuoteIcon />,
      text: "consultants.dashboard.tabs.payslip",
      url: "./payslip",
    },
    {
      icon: <EventAvailableIcon />,
      text: "consultants.dashboard.tabs.timesheet",
      url: "./timesheet",
      variant:"disabled"
    },
    {
      icon: <AddTaskIcon />,
      text: "consultants.dashboard.tabs.skills",
      url: "./skills",
      variant:"disabled"
    },
    {
      icon: <ShoppingCartIcon />,
      text: "consultants.dashboard.tabs.expense",
      url: "./expense",
    },
    {
      icon: <DirectionsCarIcon />,
      text: "consultants.dashboard.tabs.leasing",
      url: "./leasing",
    },
  ];
  const handleClick = (index, url) => {
    navigate(url);
  };

  const pathSegments = window.location.pathname.split("/");
  useEffect(() => {
    if (
      pathSegments[1] === "administration" &&
      [
        "statement",
        "information",
        "cv",
        "payslip",
        "timesheet",
        "skills",
        "expense",
        "leasing",
      ].includes(pathSegments[2])
    ) {
      // L'URL est bien formée. Insérer ici la logique souhaitée pour ces cas.
      console.log("pathSegments if", pathSegments);
    } else {
      console.log(
        "pathSegments else",
        pathSegments[1] === "administration",
        pathSegments[2],
        actions[0].url
      );
      navigate(actions[0].url);
    }
  }, []);

  return (
    <div className="flex flex-col p-secondDegree  h-full ">
      <div className="overflow-scroll items-center">
        <div className="grid grid-cols-8 gap-4 %+ml; overflow-scroll">
          {actions.map((action, index) => (
            <Action
              key={index}
              icon={action.icon}
              text={action.text}
              initialVariant={
                action.variant ||
                ("./" + pathSegments[2] === action.url ? "active" : "default")
              }
              className="gap-0 space-y-3 py-3"
              onClickAction={() => handleClick(index, action.url)}
            />
          ))}
        </div>
        <DividerLight />
        <Outlet />
      </div>
    </div>
  );
}
