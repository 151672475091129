/* eslint-disable react/prop-types */
/**
 * @component PrintNumberOfProspectInProgress
 *
 * The `PrintNumberOfProspectInProgress` component displays a notification about the number of prospects that are currently in progress. It utilizes a custom hook to fetch and monitor CV processing data, and periodically updates the display based on this data.
 *
 * Key Features:
 * - Fetches and displays the count of prospects in progress.
 * - Periodically refreshes the data to reflect the latest status.
 * - Provides notifications with different messages based on the number of prospects.
 *
 * Props:
 * - @param {Function} setIsDoingAction - Function to set the state indicating whether an action is ongoing. It is called with `true` when an action starts and potentially `false` later on.
 * - @param {boolean} shouldStopRefecth - Optional flag to determine whether refetching should be stopped. Defaults to `false`.
 * - @param {Function} refetchTableData - Function to refetch the data for the prospects table.
 *
 * Local State:
 * - No local state is managed within this component. It relies on props and external data fetching.
 *
 * Data Fetching:
 * - Uses the `useAllCvProcessing` custom hook to fetch CV processing data. The hook provides the data, loading state, error state, and a `refetch` function.
 * - Refetches data periodically based on conditions and updates the display accordingly.
 *
 * Effects:
 * - `useEffect` hooks are used to trigger data refetching based on various dependencies including loading state, `setIsDoingAction`, and `refetchTableData`.
 * - An interval is set up to periodically check the number of prospects in progress and refetch data as needed.
 *
 * Helper Functions:
 * - `countProspectsInProgress(data)`: Counts the number of prospects with a status of "IN_PROGRESS" from the provided data.
 *
 * Usage:
 * - This component is used to display notifications related to the number of prospects in progress, typically within a dashboard or administrative interface.
 *
 * Example:
 * ```jsx
 * <PrintNumberOfProspectInProgress
 *   setIsDoingAction={setIsDoingAction}
 *   shouldStopRefecth={false}
 *   refetchTableData={refetchTableData}
 * />
 * ```
 *
 * Dependencies:
 * - @hook useAllCvProcessing: Custom hook for fetching CV processing data.
 * - @component PrintInformationMessage: Component for displaying informational messages (not used directly but imported).
 * - @component Label: Component for displaying text labels.
 * - @mui/icons-material: Icons used in notifications.
 * - @hook useEffect: React hook for handling side effects and data fetching.
 *
 * Functions:
 * - `countProspectsInProgress(data)`: Counts the number of prospects with a status of "IN_PROGRESS".
 *
 * @example
 * const data = [
 *   { status: 'IN_PROGRESS' },
 *   { status: 'COMPLETED' }
 * ];
 *
 * <PrintNumberOfProspectInProgress
 *   setIsDoingAction={setIsDoingAction}
 *   shouldStopRefecth={false}
 *   refetchTableData={refetchTableData}
 * />
 */

import React from "react";
import useAllCvProcessing from "@/services/admin-services/query/useAllCvProcessing";
import { useEffect, useState } from "react";
import PrintInformationMessage from "@/components/organisms/feedback/PrintInformationMessage";
import { Label } from "@/components/atoms/texts/label";

export default function PrintNumberOfProspectInProgress({
  setIsDoingAction,
  shouldStopRefecth=false, refetchTableData,
  shouldPrintNumberOfProspects,
  setShouldPrintNumberProspects
}) {
  const date = new Date();
  //const [actualDate, setActualDate] = useState(null);
  // Soustrait 2 minutes à la date actuelle
  //  date.setMinutes(date.getMinutes() - 3);

  // Convertit la date au format ISO avec un décalage de temps explicite
  const createdAfter = date.toISOString().replace(/\.\d{3}Z$/, "+00:00");

  const { data, refetch, loadingAllCvProcessing, errorAllCvProcessing } =
    useAllCvProcessing("2024-08-30T09:37:34+00:00");
  //   const [dataUpdated, setDataUpdated] = useState(data);

  useEffect(() => {
    refetch();
    setIsDoingAction(true);
    !shouldStopRefecth && refetchTableData();
    // setDataUpdated(data);
  }, [loadingAllCvProcessing]);

  useEffect(() => {
    // Fonction pour vérifier et arrêter l'intervalle
    const checkAndRefetch = () => {
      if (countProspectsInProgress(data) > 0) {
        !shouldStopRefecth &&  setIsDoingAction(true);
        refetch();
     !shouldStopRefecth  && refetchTableData();
        // setDataUpdated(data);
      } else {
        clearInterval(interval);
      }
    };

    // Créer un intervalle qui exécute la fonction refetch toutes les 3 secondes
    const interval = setInterval(() => {
      checkAndRefetch();
    }, 1000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  }, [refetch, data]);

   useEffect(() => {
    // Fonction pour vérifier et arrêter l'intervalle
    const checkAndRefetch = () => {
      if (shouldPrintNumberOfProspects) {
        !shouldStopRefecth &&  setIsDoingAction(true);
        refetch();
     !shouldStopRefecth  && refetchTableData();
     if(countProspectsInProgress(data) <0) setShouldPrintNumberProspects(false)
        // setDataUpdated(data);
      } else {
        clearInterval(interval);
      }
    };

    // Créer un intervalle qui exécute la fonction refetch toutes les 3 secondes
    const interval = setInterval(() => {
      checkAndRefetch();
    }, 1000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  }, [shouldPrintNumberOfProspects]);

  useEffect(() => {
     !shouldStopRefecth  &&  setIsDoingAction(true);
   !shouldStopRefecth &&  refetchTableData();
    // setDataUpdated(data);
  }, []);

  function countProspectsInProgress(data) {
    return data?.filter((cv) => cv.status === "IN_PROGRESS").length || 0;
  }

  return (
    <>
      {countProspectsInProgress(data) != 0 && (
        <div
          className={`flex gap-2  p-secondDegree border border-information flex flex-row rounded-sm bg-information20 `}
        >
          <Label message={"prospects.prospectGenerationNotif"} />
          <Label>{countProspectsInProgress(data)}</Label>
          {countProspectsInProgress(data) > 1 && (
            <Label message={"prospects.prospectsHaveBeenGenerated"} />
          )}
          {countProspectsInProgress(data) == 1 && (
            <Label message={"prospects.prospectsHasBeenGenerated"} />
          )}
        </div>
      )}
      
    </>
  );
}
