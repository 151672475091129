import { createContext, useContext, useReducer, useEffect } from 'react';
import { gql, useQuery, useMutation } from "@apollo/client";
import { useAuth } from '@/context/AuthContext';

// Déclaration des requêtes GraphQL
const GET_NOTIFICATIONS_LAST_KNOWN_UUID = gql`
  query Notifications($lastKnownUuid: ID) {
    notifications(lastKnownUuid: $lastKnownUuid) {
      uuid
      createdAt
      type
      nature
      message
      readStatus
      deepLink
    }
  }
`;

const READ_NOTIFICATION = gql`
  mutation ReadNotification($uuid: ID!) {
    readNotification(uuid: $uuid)
  }
`;

const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($uuid: ID!) {
    deleteNotification(uuid: $uuid)
  }
`;

// Initial state pour le reducer
const initialState = {
    notifications: [],
    informations: [],
    actions: [],
    lastKnownUuid: null,
    error: null,
};
const notificationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATIONS':
            const sortedData = [...action.payload].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Copie du tableau avant de trier
            return {
                ...state,
                notifications: [...state.notifications, ...sortedData], // Copie du tableau avant la concaténation
                informations: [
                    ...state.informations,
                    ...sortedData.filter(notification => notification.nature === 'NOTIFICATION')
                ],
                actions: [
                    ...state.actions,
                    ...sortedData.filter(notification => notification.nature === 'ACTION')
                ],
                lastKnownUuid: sortedData.length > 0 ? sortedData[0].uuid : state.lastKnownUuid,
            };
        case 'MARK_AS_READ':
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.uuid === action.uuid ? { ...notification, readStatus: 'READ' } : { ...notification }
                ),
                informations: state.informations.map(notification =>
                    notification.uuid === action.uuid ? { ...notification, readStatus: 'READ' } : { ...notification }
                ),
                actions: state.actions.map(notification =>
                    notification.uuid === action.uuid ? { ...notification, readStatus: 'READ' } : { ...notification }
                ),
            };
        case 'DELETE_NOTIFICATION':
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.uuid !== action.uuid),
                informations: state.informations.filter(notification => notification.uuid !== action.uuid),
                actions: state.actions.filter(notification => notification.uuid !== action.uuid),
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};


// Création du contexte
const NotificationContext = createContext();

// Provider du contexte
export function NotificationProvider({ children }) {
    const { user } = useAuth();
    const [state, dispatch] = useReducer(notificationReducer, initialState);
    const { notifications, informations, actions, lastKnownUuid, error } = state;

    
    // Utilisation de la requête GraphQL pour obtenir les notifications
    const { loading, data, refetch } = useQuery(GET_NOTIFICATIONS_LAST_KNOWN_UUID, {
        variables: { lastKnownUuid },
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        fetchPolicy: "network-only",
        onError: (err) => {
            dispatch({
                type: 'SET_ERROR',
                payload: err,
            });
        },
    });

    // Utilisation des mutations pour marquer comme lu et supprimer
    const [readNotificationMutation] = useMutation(READ_NOTIFICATION, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        onError: (err) => {
            console.error("Error marking notification as read:", err);
        },
    });

    const [deleteNotificationMutation] = useMutation(DELETE_NOTIFICATION, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },
        onError: (err) => {
            console.error("Error deleting notification:", err);
        },
    });

    // Effect pour traiter les nouvelles données et mettre à jour les états
    useEffect(() => {
        if (data && Array.isArray(data.notifications)) {
            dispatch({
                type: 'SET_NOTIFICATIONS',
                payload: data.notifications,
            });
        }
    }, [data]);

    // Effect pour la récupération périodique des notifications
    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 15000); // Rafraîchissement toutes les 15 secondes

        return () => clearInterval(interval);
    }, [refetch, lastKnownUuid]);

    // Fonction pour marquer une notification comme lue
    const readNotification = async (uuid) => {
        try {
            const { data } = await readNotificationMutation({ variables: { uuid } });
            if (data.readNotification === true) {
                dispatch({ type: 'MARK_AS_READ', uuid });
            }
        } catch (error) {
            console.error("Failed to mark notification as read:", error);
        }
    };

    // Fonction pour supprimer une notification
    const deleteNotification = async (uuid) => {
        try {
            const { data } = await deleteNotificationMutation({ variables: { uuid } });
            if (data.deleteNotification === true) {
                dispatch({ type: 'DELETE_NOTIFICATION', uuid });
            }
        } catch (error) {
            console.error("Failed to delete notification:", error);
        }
    };

    // Fonction pour marquer toutes les notifications comme lues
    const markAllAsRead = async (type) => {
        const relevantNotifications = type === 'informations' ? informations : actions;
        for (const notification of relevantNotifications) {
            if (notification.readStatus === 'UNREAD') {
                await readNotification(notification.uuid);
            }
        }
        refetch();
    };

    // Fonction pour trier les notifications
    const sortNotifications = (order, type) => {
        const sortedNotifications = type === 'information' ? [...informations] : [...actions];
        sortedNotifications.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return order === 'asc' ? dateA - dateB : dateB - dateA;
        });

        if (type === 'information') {
            dispatch({
                type: 'SET_NOTIFICATIONS',
                payload: sortedNotifications,
            });
        } else {
            dispatch({
                type: 'SET_NOTIFICATIONS',
                payload: sortedNotifications,
            });
        }
    };
    if(!user?.strongToken) return <NotificationContext.Provider>{children}</NotificationContext.Provider>

    return (
        <NotificationContext.Provider value={{
            notifications,
            informations,
            actions,
            readNotification,
            deleteNotification,
            markAllAsRead,
            sortNotifications,
            loading,
            error,
            refetch
        }}>
            {children}
        </NotificationContext.Provider>
    );
}

// Hook pour utiliser le contexte
export const useNotifications = () => useContext(NotificationContext);
