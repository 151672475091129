// // import { useAuth } from "@/context/AuthContext";
// // import { useState, useEffect } from "react";

// // const useGetExpenseReportProof_AsUser = (expenseReportFileUuid, token) => {
// //     const [proof, setProof] = useState(null);
// //     const [loading, setLoading] = useState(false);
// //     const [error, setError] = useState(null);
// //     const { user } = useAuth();

// //     useEffect(() => {
// //         const getProof = async () => {
// //             if (!expenseReportFileUuid || !token) {
// //                 return;
// //             }
// //             setLoading(true);
// //             setError(null);

// //             try {
// //                 const response = await fetch(
// //                     window.ENV.BACKEND_URL+`/api/secured/users/expense/expenseReportProof/${expenseReportFileUuid}`,
// //                     {
// //                         method: "GET",
// //                         headers: {
// //                             Authorization: `Bearer ${user.strongToken}`,
// //                             "Content-Type": "multipart/form-data",
// //                         },
// //                     }
// //                 );

// //                 if (!response.ok) {
// //                     throw new Error("Failed to get expense report proof");
// //                 }

// //                 const proofBlob = await response.blob();
// //                 const proofUrl = URL.createObjectURL(proofBlob);

// //                 setProof(proofUrl);
// //             } catch (err) {
// //                 setError(err.message);
// //             } finally {
// //                 setLoading(false);
// //             }
// //         };

// //         getProof();
// //     }, [expenseReportFileUuid, token]);

// //     return {  loading, error, proof };
// // };

// // export default useGetExpenseReportProof_AsUser;
// // import { useAuth } from "@/context/AuthContext";
// // import { useState, useEffect } from "react";
// // import { useParams } from "react-router-dom";

// // const useGetExpenseReportProof_AsAdmin = ( expenseReportFileUuid) => {
// //     const [proof, setProof] = useState(null);
// //     const [loading, setLoading] = useState(false);
// //     const [error, setError] = useState(null);
// //     const { user } = useAuth();
// //     const { id } = useParams;
// //     if (!user || !id) return;

// //     console.log("test useGetExpenseReportProof_AsAdmin", expenseReportFileUuid);

// //     useEffect(() => {
// //         const fetchProof = async () => {
// //             if (!consultantUuid || !expenseReportFileUuid || !token) {
// //                 return;
// //             }

// //             setLoading(true);
// //             setError(null);

// //             try {
// //                 const response = await fetch(
// //                     window.ENV.BACKEND_URL+`/api/secured/admin/expense/expenseReportProof/${id}/${expenseReportFileUuid}`,
// //                     {
// //                         method: "GET",
// //                         headers: {
// //                             Authorization: `Bearer ${user.strongToken}`,
// //                             "Content-Type": "multipart/form-data",
// //                         },
// //                     }
// //                 );

// //                 if (!response.ok) {
// //                     throw new Error("Failed to fetch expense report proof");
// //                 }

// //                 const proofBlob = await response.blob();
// //                 const proofUrl = URL.createObjectURL(proofBlob);
// //                 console.log("test useGetExpenseReportProof_AsAdmin response =", response);

// //                 setProof(proofUrl);
// //             } catch (err) {
// //                 setError(err.message);
// //             } finally {
// //                 setLoading(false);
// //             }
// //         };

// //         fetchProof();
// //     }, [expenseReportFileUuid]);

// //     return { loading, error, proof };
// // };

// // export default useGetExpenseReportProof_AsAdmin;
// import { useAuth } from "@/context/AuthContext";
// import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";

// const useGetExpenseReportProof_AsUser = (expenseReportFileUuid) => {
//     const [proof, setProof] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const { user } = useAuth();

//     // Vérification de la validité des paramètres avant de continuer
//     if (!user) return { loading, error, proof };

//     console.log("test useGetExpenseReportProof_AsUser", expenseReportFileUuid);

//     useEffect(() => {
//         const fetchProof = async () => {
//             if ( !expenseReportFileUuid || !user.strongToken) {
//                 return;
//             }

//             setLoading(true);
//             setError(null);

//             try {
//                 const response = await fetch(
//                      window.ENV.BACKEND_URL+`/api/secured/admin/expense/expenseReportProof/${expenseReportFileUuid}`,{
//                         method: "GET",
//                         headers: {
//                             Authorization: `Bearer ${user.strongToken}`,
//                             "Content-Type": "multipart/form-data",
//                         },
//                     }
//                 );

//                 if (!response.ok) {
//                     throw new Error("Failed to fetch expense report proof");
//                 }

//                 // Récupérer le fichier sous forme de Blob
//                 const proofBlob = await response.blob();
//                 setProof(proofBlob);  // Stocker le Blob directement
//             } catch (err) {
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchProof();
//     }, [ expenseReportFileUuid]);  

//     return { loading, error, proof };
// };

// export default useGetExpenseReportProof_AsUser;


import { useState } from "react";
import { useAuth } from "@/context/AuthContext";

export const useGetExpenseReportProof_AsUser = (expenseReportFileUuid) => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const getExpenseProof = async (ExpenseProofFileUuid) => {
        setLoading(true);
        setError(null);
// f4bfbb4b-e9ee-4937-bee8-202c455be206
        try {
            const response = await fetch( window.ENV.BACKEND_URL+`/api/secured/users/expense/expenseReportProof/${ExpenseProofFileUuid}`,{
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to retrieve expense invoice");
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            console.log("blob file expense ", blob, url)

            setData({ url, type: blob.type });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { getExpenseProof, loading, error, data };
};
