import Filter from "@/components/organisms/Filter";
import PolarAreaChart from "@/components/organisms/charts/PolarAreaChart";
import React from "react";
import { Button } from "@/components/molecules/buttons/button";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import useCVTab from "@/hooks/global-hooks/useCVTab";
import { useState, useEffect } from "react";
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import FetchingData from "@/components/molecules/PrintFetchingData";
import { useTranslation } from "react-i18next";
import PrintSkill from "@/components/macro-organisms/panels/panels-admin/prospectConsultant/skills/PrintSkill";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { useConsultantContext } from "@/context/ConsultantContext";
import useActiveUser from "../../consultants-vision-admin/useActiveUser";
import useStatementData from "@/hooks/consultant-hooks/useStatementData";
import { useParams } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import TrainingCard from "./card/TrainingCard";
import { Label } from "@/components/atoms/texts/label";
import AddSkill from "@/components/macro-organisms/panels/panels-admin/prospectConsultant/skills/AddSkill";
import AddIcon from "@mui/icons-material/Add";

const mapSkills = [
  { typeIndata: "hard", type: "Hard skills" },
  { typeIndata: "soft", type: "Soft skills" },
  { typeIndata: "power", type: "Power skills" },
];

export default function SkillsPageConsultant() {
  const { CVData, error } = useCVTab();
  const [skills, setSkills] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const { user } = useAuth();
  const [selectedItem, setSelectedItem] = useState("");
    const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (CVData) {
      if (CVData.skills) {
        setSkills(CVData.skills);
        setAllSkills(CVData.skills);
      }
    }
    // console.log(getTitles(CVData.project))
  }, [CVData]);

  useEffect(() => {
    const getSkillTypeName = (type) => {
      // Recherche le type basé sur 'typeIndata'
      const matchingSkillType = mapSkills.find((skill) => skill.type === type);

      // Retourne le type correspondant ou un message par défaut
      return matchingSkillType ? matchingSkillType.typeIndata : "unknown";
    };
    const filterSkills = (skillType) => {
      const correspondingType = getSkillTypeName(skillType);
      if (correspondingType != "unknown") {
        const skillsFiltered = allSkills.filter(
          (skill) => skill.type === correspondingType && skill
        );
        setSkills(skillsFiltered);
        //selectedItem(correspondingType);
      } else {
        setSkills(allSkills);
      }
      //   selectedItem(correspondingType);
    };
    filterSkills(selectedItem);
  }, [selectedItem]);

  if (error) {
    return <PrintErrorMessage error={""}/>;
  }
  if (!CVData) {
    return <FetchingData />;
  }
 const updateSkills = (updatedSkill) => {
   setSkills((prevSkills) => {
     // Trouver l'index du skill à mettre à jour
     const skillIndex = prevSkills.findIndex(
       (skill) => skill.id === updatedSkill.id
     );

     if (skillIndex === -1) {
       console.error("Skill not found: ", updatedSkill.id);
       return prevSkills; // Retourne le tableau inchangé si le skill n'est pas trouvé
     }

     // Créer une nouvelle liste avec le skill mis à jour
     const newSkills = [...prevSkills];
     newSkills[skillIndex] = { ...newSkills[skillIndex], ...updatedSkill }; // Mettre à jour le skill

     return newSkills; // Retourne la nouvelle liste de skills
   });
 };

  return (
    <div className=" w-full grid grid-cols-3  gap-3 mb-[100px] p-secondDegree">
      <div className=" w-full col-span-2 grid gap-fourthDegree justify-items-center px-[80px]">
        <Filter
          items={["Global", "Soft skills", "Hard skills", "Power skills"]}
          className="w-[335px]"
          informSelectedItem={setSelectedItem}
        />
        <PolarAreaChart
          label={skills.map((skill) => skill.title)}
          datas={skills.map((skill) => skill.level)}
        />
      </div>
      <div className="w-full flex flex-col  justify-items-end gap-secondDegree ">
        <TrainingCard />
        <div className="gap-betweenText flex flex-row items-center bg-gradientCustom justify-center rounded-sm p-firstDegree ">
          <Label message="consultants.skillsPage.youHave" size="body" />{" "}
          <Label>{32}</Label>
          <Label message="consultants.skillsPage.nonAssedSkills" size="body" />
        </div>

        <div className=" flex flex-col  truncate py-paddingTopAndBottom px-paddingLeftAndRight w-full  overflow-scroll">
          <div className="flex flex-ro justify-between items-center py-firstDegree">
            <Label size="h3" fontWeight="bold">
              {" "}
              {selectedItem}
            </Label>
            <Button
              showLeftIcon={true}
              leftIcon={<AddIcon fontSize="small" />}
              showText={true}
              text="global.actions.add"
              sizePadding="default" // Utilisez "small" pour un padding plus petit
              style="ghost"
              className="font-bold"
              onClick={() => setShowForm(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
            />
          </div>

          <Separator />

          {showForm && (
            <div className="flex flex-col gap-secondDegree">
              {" "}
              <AddSkill
                showForm={showForm}
                setShowForm={setShowForm}
                setSkills={setSkills}
              />
              <Separator orientation="horizontal" className="bg-black" />
            </div>
          )}
          {skills.map((skill, index) => {
            return (
              <PrintSkill key={index} skill={skill} setSkills={updateSkills} />
            );
          })}
        </div>
      </div>
    </div>
  );
}
