import React, { useRef, useState, useEffect } from 'react';
import Payslip from "@/components/macro-organisms/tabs/consultants-vision-admin/payslib/payslip";
import usePayslipData from "../../consultants-vision-admin/payslib/usePayslipData";
import { Label } from '@/components/atoms/texts/label';
import Chip from '@/components/molecules/buttons/Chip';
import { usePayslips } from '@/services/consultant-services/query/usePayslips';
import { useParams } from 'react-router-dom';
import InViewComponent from '@/components/organisms/InViewComponent';
import PrintEmptyDataMessage from '@/components/molecules/PrintEmptyDataMessage';




export default function PayslipTabUser() {
    const [payslipsCopy, setPayslipsCopy] = useState([]);
    const { loading, error, data, refetch } = usePayslips(1);
    const { id } = useParams();


    // gestion des slices : 
    const [lastSlice, setLastSlice] = useState(1);
    // Requête initiale lors du changement d'ID
    useEffect(() => {
        refetchFromScratch();
    }, [id]);

    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setPayslipsCopy([]);
        refetch(n);
        console.log("refetch from Scratch")
    };
    const fetchNewSlice = () => {
        console.log("fetchNewSlice", data?.hasNext, lastSlice)
        if (!loading && payslipsCopy.length > 0 && data?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    useEffect(() => {
        if (!loading && data) {

            setPayslipsCopy((prevPayslip) => [
                ...prevPayslip,
                ...data.payslips
            ]);
        }
        console.log("payslips are :", data);
    }, [data]);


    return (
        // <>payslip tab for user</>
        <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">

            {error && <PrintErrorMessage error={error} />}
            {loading && <span className='loader' />}
            {(!error && !loading) && payslipsCopy?.map((item, index) => (
                <Payslip key={index} data={item} />
            ))}
            {!error && !loading &&
                payslipsCopy?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataPayslip" />}
            <InViewComponent inView={fetchNewSlice} />

        </div>
    )
}