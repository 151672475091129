import React, { useRef, useState, useEffect } from 'react';

import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { AddRounded, DirectionsCar } from '@mui/icons-material';

import Action from '@/components/molecules/buttons/Action';
import TimesheetCalendar from './TimesheetCalendar';
import { useTimeEntriesData } from './useTimesheetData';
import transformTimeEntriesToEvents from './transformTimeEntriesToEvents';
import EditIcon from "@mui/icons-material/Edit";

export default function NewTimesheetPanel({ global = true, isEditing , year, month, timesheetId}) {
    const submitRef = useRef(null);
    const [openNewTimesheet, setOpenNewTimesheet] = useState(false);
    const { timeEntries, error } = useTimeEntriesData(year, month, timesheetId);
    const [timeEntriesForCalendar, setTimeEntriesForCaledar] = useState();

    useEffect(() => {
        console.log("test NewTimesheetPanel", timeEntries, error, year, month)
        if (timeEntries) {
            const transformedEvents = transformTimeEntriesToEvents(timeEntries, year, month);
            console.log(" ShowTimesheet timeEntries_", timeEntries, transformedEvents);
            setTimeEntriesForCaledar(transformedEvents);
        }
        if (error) {
            console.error("Error fetching time entries: ", error);
        }
    }, [timeEntries, error, year, month]);

    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        try {
            // const response = await fetch('API_URL', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(formData)
            // });
            // const data = await response.json();
            // console.log("Response:", data);


        } catch (error) {
            console.error("Erreur lors de la soumission:", error);
        }
    };
    return (
        <Sheet open={openNewTimesheet} onOpenChange={setOpenNewTimesheet}>
            <SheetTrigger className={global && `w-full`}>

                {global ? (
                    <Action
                        icon={<DirectionsCar />}
                        text="consultants.dashboard.actions.timesheet"
                        initialVariant={openNewTimesheet ? "active" : "default"}
                    />
                ) : (
                    isEditing ? <Button style="pill" leftIcon={< EditIcon fontSize='small' />} showLeftIcon showText={false} sizePadding="pill" text="global.actions.edit" /> :
                        < Button leftIcon={< AddRounded />} showLeftIcon text="consultants.timesheet.addTimesheet" />
                )}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={isEditing ? "global.actions.edit" : "consultants.timesheet.addTimesheet"}
                />
                <SheetSlot className=" flex  relative">
                    <TimesheetCalendar needAbsolutePopUp timeEntries={isEditing && timeEntriesForCalendar}/>
                </SheetSlot>
                <SheetFooter
                    // buttonOutlineText={"global.actions.clear"}
                    buttonDressedText={"global.actions.save"}
                    // warning={"global.userBeNotified"}
                    // onClickOutline={(e) => submitRef.current && submitRef.reset.resetForm()}
                    onClickDressed={() => submitRef.current && submitRef.current.click()}

                />
            </SheetContent>
        </Sheet>
    )
}