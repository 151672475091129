import React from 'react'
import { Label } from '../../atoms/texts/label';
export default function PrintInformationMessage({information, isComponent=false, className}) {

  return (
    <div
      className={`flex flex-col p-secondDegree border border-information rounded-sm bg-information20 ${className}`}
    >
      {isComponent && information}
      {!isComponent && <Label message={information}>{information}</Label>}
    </div>
  );
}
