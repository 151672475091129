// import React, { useRef } from "react";
// import { Button } from "@/components/molecules/buttons/button";
// import AddRoundedIcon from "@mui/icons-material/AddRounded";
// import Expense from "./Expense";
// import {
//     Sheet,
//     SheetTrigger,
//     SheetContent,
//     SheetHeader,
//     SheetSlot,
//     SheetFooter,
//     SheetClose,
// } from "@/components/templates/sheet";
// import FormNewExpense from "./formNewExpense";
// import useExpenseData from "./useExpenseDataUser";
// import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
// import FormIsSend from "@/components/organisms/feedback/formIsSend";
// import { Label } from "@/components/atoms/texts/label";

// export default function ExpenseTabUser() {
//     const submitRef = useRef(null);
//     const { expenses, error } = useExpenseData();

//     const handleSubmit = async (formData) => {
//         console.log("Form data:", formData);
//         try {

//             // comportement souhaité : envoyer en premier lieu le fichier pdf via useAddExpenseReportProof puis stocker l'uuid retourné dans expenseReportFileUuid
//             // après l'envoi du fichier et la réception de son uuid, envoyer l'expense via useAddExpenseReport en y ajoutant expenseReportFileUuid
//             // si un des envoi leve une erreur on affiche PrintErrorMessage sinon FormIsSend
//         } catch (c) {
//             console.error("Erreur lors de la soumission:", error);
//         }
//     };
//     return (
//         <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">
//             <Sheet>
//                 <SheetTrigger>
//                     <Button leftIcon={<AddRoundedIcon />} showLeftIcon>
//                         {/* TODO : mettre texte dans le json */}
//                         New expense account
//                     </Button>
//                 </SheetTrigger>
//                 <SheetContent>
//                     <SheetHeader title={"consultants.expense.newExpense"} />
//                     <SheetSlot className=" flex ">
//                         <PrintErrorMessage error={"message d'erreur reemonté par le back"}/>
//                         <FormIsSend message={<Label>"test"</Label>}/>
//                         <FormNewExpense onSubmit={handleSubmit} submitRef={submitRef} />
//                     </SheetSlot>
//                     <SheetFooter
//                         buttonOutlineText={"global.actions.clear"}
//                         buttonDressedText={"global.actions.ConfirmNext"}
//                         warning={"consultants.expense.fileAdded"}
//                         onClickOutline={(e) =>
//                             submitRef.current && submitRef.reset.resetForm()
//                         }
//                         onClickDressed={() =>
//                             submitRef.current && submitRef.current.click()
//                         }
//                     />
//                 </SheetContent>
//             </Sheet>

//             {!error &&
//                 expenses &&
//                 expenses.map((item, index) => (
//                     <Expense key={index} data={item} dataOwner />
//                 ))}
//         </div>
//     );
// }

import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Expense from "./Expense";
import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
    SheetFooter,
} from "@/components/templates/sheet";
import FormNewExpense from "./formNewExpense";
import useExpenseData from "./useExpenseDataUser";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
import { Label } from "@/components/atoms/texts/label";
import useAddExpenseReportProof from "@/services/consultant-services/api-rest/useAddExpenseReportProof";
import { useAddExpenseReport } from "@/services/consultant-services/mutation/useAddExpenseReport";
import { useExpenseReports } from "@/services/consultant-services/query/useExpenseReports";
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";
import InViewComponent from "@/components/organisms/InViewComponent";
import { Toaster } from "@/components/organisms/feedback/toaster";

export default function ExpenseTabUser() {
    const submitRef = useRef(null);
    const [lastSlice, setLastSlice] = useState(1);

    // const { expenses, error } = useExpenseData();
    const [submissionError, setSubmissionError] = useState(null);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    const { addExpenseReportProof } = useAddExpenseReportProof();
    const { addExpenseReport, loading: loadingAddExpense, error: errorAddExpense, data: dataAddExpense } = useAddExpenseReport();
    const { loading, error, expenseReports, refetch } = useExpenseReports(lastSlice); // Utilisation de useExpenseReports

    const [expensesCopy, setExpensesCopy] = useState([]);


    // useEffect(() => {
    //     refetchFromScratch();
    // }, []);

    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setExpensesCopy([]);
        refetch(n);
        console.log(n);
    };

    const fetchNewSlice = () => {
        console.log(
            "fetchNewSlice",
            expenseReports?.hasNext,
            lastSlice,
            "loading =",
            loading,
            error,
            "expenseReports =",
            expenseReports,
            "setExpensesCopy =",
            expensesCopy.length > 0
        );
        if (!loading && expensesCopy.length > 0 && expenseReports?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    // Concaténation des nouvelles données avec les anciennes
    useEffect(() => {
        if (!loading && expenseReports) {
            // Mettre à jour la copie des dépenses avec les nouvelles données
            setExpensesCopy((prevExpenses) => [
                ...prevExpenses,
                ...expenseReports.expenses,
            ]);
        }
    }, [expenseReports, loading]);

    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        try {
            // Réinitialiser les états
            setSubmissionError(null);
            setSubmissionSuccess(false);

            // Étape 1 : Envoyer le fichier et récupérer l'UUID
            const fileResponse = await addExpenseReportProof(formData.file);
            const expenseReportFileUuid = fileResponse.data.uuid;

            // Étape 2 : Envoyer la note de frais avec l'UUID du fichier
            const newExpenseReport = {
                ...formData,
                expenseReportFileUuid, // Ajouter l'UUID du fichier
            };
            await addExpenseReport(newExpenseReport);

            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        } catch (err) {
            // Gestion des erreurs
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
        return { submissionSuccess, submissionError }
    };

    useEffect(() => {
        if (dataAddExpense?.addExpenseReport?.uuid) {
            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        console.log("use effect data", dataAddExpense)
        errorAddExpense && setSubmissionError(errorAddExpense)

    }, [errorAddExpense, dataAddExpense]);

    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">
            <Toaster />
            <Sheet>
                <SheetTrigger>
                    {/* //TODO texte dans global */}
                    <Button leftIcon={<AddRoundedIcon />} showLeftIcon>
                        New expense account
                    </Button>
                </SheetTrigger>
                <SheetContent>
                    <SheetHeader
                        title={"consultants.expense.newExpense"}
                        subheader={"global.information.allFieldsMandatory"}
                    />
                    <SheetSlot className="flex">
                        {submissionError && (
                            <PrintErrorMessage error={submissionError} />
                        )}
                        {submissionSuccess && (
                            <FormIsSend message={<Label>Expense submitted successfully!</Label>} />
                        )}
                        <FormNewExpense onSubmit={handleSubmit} submitRef={submitRef} submissionError={submissionError} submissionSuccess={submissionSuccess} />
                        {loadingAddExpense && <span className="loader" />}
                    </SheetSlot>
                    <SheetFooter
                        buttonOutlineText={"global.actions.clear"}
                        buttonDressedText={"global.actions.ConfirmNext"}
                        warning={"consultants.expense.fileAdded"}
                        onClickOutline={(e) =>
                            submitRef.reset && submitRef.reset.resetForm()
                        }
                        onClickDressed={() =>
                            submitRef.current && submitRef.current.click()
                        }
                    />
                </SheetContent>
            </Sheet>

            {/* {!error &&
                expenses &&
                expenses.map((item, index) => (
                    <Expense key={index} data={item} dataOwner />
                ))} */}

            {error ? <PrintErrorMessage error={error} /> :
                expensesCopy?.map((item, index) => (
                    <Expense key={index} data={item} dataOwner refetch={refetchFromScratch} />
                ))}

            {!error && !loading &&
                expenseReports?.expenses?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataExpense" />}

            {loading && <div className="w-full flex justify-center"><span className="loader " /></div>}

            <InViewComponent inView={fetchNewSlice} />
        </div>
    );
}
