import React, { useRef, useState, useEffect } from 'react';
import Leasing from "@/components/macro-organisms/tabs/consultant-vision-user/leasing/Leasing";

import { Toaster } from '@/components/organisms/feedback/toaster';
import { useLeasingInvoices } from '@/services/consultant-services/query/useLeasingInvoices';
import InViewComponent from '@/components/organisms/InViewComponent';
import PrintEmptyDataMessage from '@/components/molecules/PrintEmptyDataMessage';



export default function LeasingTab() {
    // const { leasings, error } = useLeasingData();
    const [lastSlice, setLastSlice] = useState(1);

    const [leasingsCopy, setLeasingsCopy] = useState([]);
    const { loading, error, data: leasingInvoices, refetch } = useLeasingInvoices(lastSlice);


    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setLeasingsCopy([]);
        refetch(n);
        console.log(n)
    };

    const fetchNewSlice = () => {
        console.log("fetchNewSlice", leasingInvoices?.hasNext, lastSlice, "loading =", loading, error, "leasingInvoices =", leasingInvoices, "setLeasingsCopy =", leasingsCopy.length > 0)
        if (!loading && leasingsCopy.length > 0 && leasingInvoices?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    // Concaténation des nouvelles données avec les anciennes
    useEffect(() => {
        if (!loading && leasingInvoices) {


            // Mettre à jour la copie des dépenses avec les nouvelles données
            setLeasingsCopy((prevLeasing) => [
                ...prevLeasing,
                ...leasingInvoices.leasings
            ]);
        }
    }, [leasingInvoices, loading]);

    useEffect(() => {
        refetchFromScratch();
    }, []);
    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">

            <Toaster />

            {(!error && !loading && leasingsCopy) && leasingsCopy.map((item, index) => (
                <Leasing key={index} data={item} dataOwner={false} refetchFromScratch={refetchFromScratch} />
            ))}
            {loading && <div className="w-full flex justify-center"><span className="loader " /></div>}
            {!error && !loading &&
                leasingsCopy?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataLeasing" />}
            <InViewComponent inView={fetchNewSlice} />

        </div>
    )
}
