/**
 * @component SetSkillsPanel
 *
 * The `SetSkillsPanel` component is used to manage and assign skills to consultants or prospects in a dashboard interface.
 * It provides functionality to add new skills, view existing skills, and assign them to consultants.
 *
 * Key Features:
 * - Displays a list of available skills.
 * - Allows users to add new skills through a form.
 * - Supports dynamic fetching and updating of skill data from an API.
 * - Enables assigning skills to consultants.
 * - Localized in multiple languages via `useTranslation`.
 *
 * Props:
 * - {...props} Any additional props passed to the component.
 *
 * State:
 * - @variable {Boolean} isOpen - Toggles the visibility of the skills panel.
 * - @variable {Array} skills - Holds the list of skills added for a consultant.
 * - @variable {Boolean} changeContent - Controls the display state for adding new skills or viewing assigned skills.
 * - @variable {String} skillName - Stores the name of the selected skill.
 * - @variable {Number} numberOfConsultant - Tracks the number of consultants assigned to a skill.
 * - @variable {Boolean} addingNewSkill - Determines if the form for adding a new skill is shown.
 * - @variable {Boolean} isdeleting - Indicates if a skill is being deleted.
 * - @variable {Object|null} selectedSkill - Stores the currently selected skill for viewing or updating.
 * - @variable {Object|null} allSkills - Holds all available skills fetched from the API.
 *
 * Hooks/Effects:
 * - @hook {useSetSkillPanelData} - Custom hook to manage skill panel data, including added skills and error handling.
 * - @hook {useAllSkills} - Fetches all available skills from the API.
 * - @hook {useTranslation} - Provides support for multi-language translations.
 * - @effect Fetches and updates skills when the panel is opened or a skill is added/deleted.
 *
 * Helper Variables:
 * - @constant {Object} skillsMapLang - Maps the current language code to the appropriate skill label field.
 *
 * Conditional Rendering:
 * - Renders the form for adding a new skill if `addingNewSkill` is `true`.
 * - Renders a list of assigned skills and their consultants when `changeContent` is `false`.
 * - Shows different skill labels depending on the selected language using `skillsMapLang`.
 *
 * Example Usage:
 * ```jsx
 * <SetSkillsPanel />
 * ```
 *
 * Dependencies:
 * - React hooks (`useState`, `useEffect`) for managing state and lifecycle events.
 * - Custom hooks (`useSetSkillPanelData`, `useAllSkills`) for fetching and managing skill data.
 * - `useTranslation` for handling multi-language support.
 * - UI components such as `Sheet`, `SheetTrigger`, `Action`, `Button`, `Label`, `Separator`, `AddNewSkill`, and `PrintSkillAdded`.
 *
 * Where it is used:
 * -  ConsultantDashboard
 * -
 */

import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
} from "@/components/templates/sheet";
import { useState, useEffect } from "react";
import { Button } from "@/components/molecules/buttons/button";
import Action from "@/components/molecules/buttons/Action";
import AddTask from "@mui/icons-material/AddTask";
import useSetSkillPanelData from "./useSetSkillPanelData";
import PrintSkillAdded from "./PrintSkillAdded";
import AddNewSkill from "./AddNewSkill/AddNewSkill";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
// import { useAllSkills } from "@/services/global-services/useAllSkills";
import { useTranslation } from "react-i18next";
import { useAllSkills } from "@/services/global-services/query/useAllSkills";
const skillsMapLang = {
    en: "labelEn",
    es: "labelEs",
    fr: "labelFr",
    it: "labelIt",
    nl: "labelNl",
};

export default function SetSkillsPanel({ ...props }) {
    const [isOpen, setIsOpen] = useState(false);
    const { skillsAdded, error } = useSetSkillPanelData();
    const [skills, setSkills] = useState([]);
    const [changeContent, setChangeContent] = useState(false);
    const [skillName, setSkillName] = useState("");
    const [numberOfConsultant, setNumberOfConsultant] = useState(0);
    const [addingNewSkill, setAddingNewSkill] = useState(false);
    const {
        loading,
        error: errorSkills,
        data: dataSkills,
        refetch,
    } = useAllSkills();
    const [allSkills, setAllSkills] = useState(null);
    const { i18n } = useTranslation("global");
    const currentLanguageCode = i18n.language;
    const fieldLangToPrint = skillsMapLang[currentLanguageCode];
    const [isdeleting, setIsdeleting] = useState(false);
    const [selectedSkill, setSlectedSkill] = useState(null);

    //   console.log("Current lang code", fieldLangToPrint);
    useEffect(() => {
        setSkills(skillsAdded);
    }, [skillsAdded]);

    useEffect(() => {
        refetch();
        setAllSkills(dataSkills);
    }, [isOpen]);
    useEffect(() => {
        refetch();
        //setAllSkills(dataSkills);
    }, [isdeleting]);
    const repeatedNames = Array.from(
        { length: numberOfConsultant },
        (_, index) => index
    );
    //   console.log("skills added", skillsAdded)
    //   console.log("skills ff", skills)

    // if (errorSkills) return <PrintErrorMessage />;
    // if (!dataSkills) return <PrintFetchingData />;
    useEffect(() => {
        setAllSkills(dataSkills);
        // Vous pouvez effectuer des actions supplémentaires ici si nécessaire
    }, [dataSkills]);

    useEffect(() => {
        refetch();
        // Vous pouvez effectuer des actions supplémentaires ici si nécessaire
    }, [changeContent, addingNewSkill]);
    return (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
                <Action
                    icon={<AddTask />}
                    text="consultants.dashboard.actions.skills"
                    initialVariant={isOpen ? "active" : "default"}
                    {...props}
                />
            </SheetTrigger>
            <SheetContent>
                <SheetHeader title={"consultants.statement.setSkillsPanel.addSkill"} />
                <SheetSlot>
                    {addingNewSkill && (
                        <AddNewSkill
                            setSkills={setSkills}
                            setAddingSkill={setAddingNewSkill}
                        />
                    )}

                    {!addingNewSkill && (
                        <div>
                            {!changeContent && !addingNewSkill ? (
                                <div className=" flex flex-col gap-secondDegree py-paddingTopAndBottom px-paddingLeftAndRight w-full ">
                                    <Button
                                        showLeftIcon={true}
                                        leftIcon={<AddIcon />}
                                        showText={true}
                                        text="global.actions.add"
                                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                                        style="fill"
                                        className="grow "
                                        //type="submit"
                                        onClick={() => setAddingNewSkill(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                    />
                                    <Label
                                        message="consultants.statement.setSkillsPanel.activeSkills"
                                        fontWeight="bold"
                                        className="justify-center flex"
                                    />
                                    <Separator />
                                    {allSkills &&
                                        allSkills.allSkills.map((skill, index) => {
                                            return (
                                                <PrintSkillAdded
                                                    skill={skill}
                                                    key={skill.uuid}
                                                    setIsdeleting={setIsdeleting}
                                                    setChangeContent={setChangeContent}
                                                    skills={allSkills}
                                                    setNumberOfConsultant={setNumberOfConsultant}
                                                    setSkillName={setSkillName}
                                                    labelLangSkill={fieldLangToPrint}
                                                    setSlectedSkill={setSlectedSkill}
                                                />
                                            );
                                        })}
                                </div>
                            ) : (
                                <div className=" flex flex-col gap-secondDegree p-secondDegree">
                                    <div className="flex justify-start">
                                        <Button
                                            showLeftIcon={true}
                                            leftIcon={<ArrowBackIcon fontSize="small" />}
                                            showText={false}
                                            // text="global.actions.back"
                                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                                            style="ghost"
                                            className="font-bold  "
                                            onClick={() => setChangeContent(false)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-firstDegree">
                                        <div className="flex flex-row gap-betweenText">
                                            <Label
                                                message="consultants.statement.prospectConsultantPanel.formPlaceHolder.skill"
                                                fontWeight="bold"
                                            />

                                            <Label>{": " + selectedSkill[fieldLangToPrint]}</Label>
                                        </div>

                                        {/* {to change after} */}

                                        {selectedSkill.skillAssignedUsers &&
                                            selectedSkill.skillAssignedUsers.map(
                                                (consultant, index) => (
                                                    <Label key={index}>
                                                        {consultant.firstname + " " + consultant.lastname}
                                                    </Label>
                                                )
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </SheetSlot>
                {/* {!changeContent &&
          !addingNewSkill &&(
            <SheetFooter
              //warning={"header.message"}
              //consent={"header.message"}
              //closeButton={true}
              buttonOutlineText={changeContent && translationsPaths.backAction}

              // onClickOutline={handleChangeContent}
              // onClickDressed={handleCalledFunction}
            ></SheetFooter>
          )} */}
            </SheetContent>
        </Sheet>
    );
}
