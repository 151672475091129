import React, { useCallback } from 'react';
import NewTimesheetPanel from '../../consultants-vision-admin/timesheet/NewTimesheetPanel';
import {useTimesheetData} from '../../consultants-vision-admin/timesheet/useTimesheetData';
import Timesheet from '../../consultants-vision-admin/timesheet/Timesheet';
import { Toaster } from '@/components/organisms/feedback/toaster';

export default function TimesheetTabUser() {
    const { timesheets, error } = useTimesheetData();
    


    return (

        <div className="flex flex-col items-end gap-secondDegree overflow-scroll pb-secondDegree px-1">
            <div className="flex gap-secondDegree">
                <NewTimesheetPanel global={false} />
            </div>

                {(!error && timesheets) && timesheets.map((item, index) => (
                    < Timesheet key={index} data={item} dataOwner />
                ))}
        <Toaster/>

            
        </div>
    );
}

