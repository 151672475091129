import TimesheetCalendar from './TimesheetCalendar';
import transformTimeEntriesToEvents from './transformTimeEntriesToEvents';
import { useTimeEntriesData } from './useTimesheetData';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PrintEmptyDataMessage from '@/components/molecules/PrintEmptyDataMessage';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import PrintWarningMessage from '@/components/organisms/feedback/PrintWarningMessagee';


export default function ShowTimesheet({ year, month, timesheetId, totalDays }) {
    const { timeEntries, error } = useTimeEntriesData(year, month, timesheetId);
    const [timeEntriesForCalendar, setTimeEntriesForCaledar] = useState();

    useEffect(() => {
        if (timeEntries) {
            const transformedEvents = transformTimeEntriesToEvents(timeEntries, year, month);
            console.log(" ShowTimesheet timeEntries_", timeEntries, transformedEvents);
            setTimeEntriesForCaledar(transformedEvents);
        }
        if (error) {
            console.error("Error fetching time entries: ", error);
        }
    }, [timeEntries, error, year, month]);

    const getUniqueDaysWithEvents = (events) => {
        const uniqueDays = new Set(events.map(event => moment(event.start).format('YYYY-MM-DD')));
        return uniqueDays.size;
    };

    const uniqueDaysWithEvents = timeEntriesForCalendar ? getUniqueDaysWithEvents(timeEntriesForCalendar) : 0;

    return (
        <div className='flex min-h-6'>
            {timeEntries ? (
                <div className='w-full'>
                    {totalDays === 0 && <PrintErrorMessage error="error.nbDaysZero"/>}
                    {totalDays !== 0 && totalDays !== uniqueDaysWithEvents && <PrintWarningMessage warning="error.nbDaysDifferent"/>}
                    {totalDays !== 0 && <TimesheetCalendar timeEntries={timeEntriesForCalendar} viewOnly />}
                </div>
            ) : (
                <span className="loader" />
            )}
        </div>
    );
}
