import { useEffect, useState } from 'react';
import ConsultantItem from '@/components/molecules/navigation/ConsultantItem';
import useConsultantData from './useConsultantData';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import useConsultant from './useConsultant';
import { Label } from '@/components/atoms/texts/label';
import { useNavigate, useParams } from 'react-router-dom';

import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { Button } from '@/components/molecules/buttons/button';

export default function ListingConsultant() {
    

    const { id } = useParams();

    const navigate = useNavigate();

    const {
        usersFormated: users,
        usersMatched,
        searchText,
        searchConsultant,
        clearSearch,
        handleClick } = useConsultant();

    if (!users) {
        return <span className="loader m-secondDegree"></span>
    }
    return (
        <div className='w-full grow flex flex-col h-full scroll justify-start'>
            <div className='w-full flex items-center justify-center border-b-2 border-grey py-paddingTopAndBottom'>
                <Label
                    size="h4"
                    // fontWeight="regular"
                    fontFamily="mediaGothic"
                    message="menuListingConsultant.consultants"
                    className=" FONT_SANS truncate "
                />
            </div>

            <div className='flex relative mt-marginTopAndBottom mb-marginTopAndBottom mx-1 gap-firstDegree'>
                <input
                    className='border-solid  outline-none  border-secondary border-2  w-full mt-firstDegree  text-left  text-black py-2  px-3  rounded-md inputListConsultant text-body'
                    onChange={(e) => searchConsultant(e.target.value)}
                    value={searchText}
                    placeholder="Consultant"
                />
                {searchText ? (
                    <button
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer'
                        onClick={clearSearch}
                    >
                        <ClearIcon className='text-secondary mt-2' />
                    </button>
                ) : (
                    <button
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer'
                        onClick={clearSearch}
                    >
                        <SearchIcon className='text-secondary mt-2' />
                    </button>
                )
                }
            </div>

            <div className='flex flex-col justify-items-start overflow-scroll text-left grow gap-firstDegree px-1' >
                {/* we display the filtered users if exist and if not we display the list of users*/}
                {usersMatched && usersMatched.length > 0 ? (usersMatched.map((user) => (
                    <ConsultantItem
                        key={user.uuid}
                        consultantName={user.trigram + " - " + user.firstname + " " + user.lastname}
                        onClick={() => { handleClick(user) }}
                        isSelected={user.uuid == id}
                    />
                ))) : (users.length > 0 && users.map((user) => (

                    <ConsultantItem
                        key={user.uuid}
                        consultantName={user.trigram + " - " + user.firstname + " " + user.lastname}
                        onClick={() => { handleClick(user) }}
                        isSelected={user.uuid == id}
                    />

                )))}

            </div>
            <Button
                id='btU'
                showLeftIcon={true}
                leftIcon={<RecentActorsIcon />}
                showRightIcon={false}
                rightIcon=""
                showText={true}
                text="menuListingConsultant.actions"
                sizePadding="default"
                style="outline"
                disabled={!id}
                className="w-full bottom-0  mb-firstDegree font-[700]"
                onClick={() => navigate('.')}
            />
        </div>
    );
}
