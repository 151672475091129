import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import EventDialog from './EventDialog';
import { Button } from '@/components/molecules/buttons/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Label } from '@/components/atoms/texts/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import EventSettings from './EventSettings';
import transformTimeEntriesToEvents from './transformTimeEntriesToEvents';


const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const TimesheetCalendar = ({ needAbsolutePopUp = false, timeEntries, viewOnly=false }) => {
    const [events, setEvents] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [lengthEvents, setLengthEvents] = useState(1);
    const [idNewEvent, setIdNewEvent] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState(moment().startOf('month').format('YYYY-MM'));
    const [selectedClient, setSelectedClient] = useState(''); // Placeholder for client

    useEffect(() => {
        if (timeEntries) {
            // const transformedEvents = transformTimeEntriesToEvents(timeEntries_);
            console.log(" useEffect timeEntries_", timeEntries)
            setEvents(timeEntries);
        }

    }, [timeEntries]);

    /**
     * Handle slot selection in the calendar
     * @param {Object} param - Contains start and end dates of the selected slot
     */
    const handleSelectSlot = ({ start, end }) => {
        if (!viewOnly) {

            setSelectedEvent(null);

            // Adjust the end date if it is at midnight the next day
            if (moment(end).isSame(moment(start).add(1, 'days').startOf('day'))) {
                end = moment(start).endOf('day').toDate();
            }

            setSelectedSlot({ start, end, isAllDay: moment(end).diff(moment(start), 'days') >= 1 });
            setDialogOpen(true);
        }
    };

    /**
     * Render event component
     * @param {string} title - Event title
     * @param {number} idEvent - Event ID
     * @returns {JSX.Element} - Event component
     */
    const eventComp = (title, idEvent) => (
        <div className="flex justify-center rounded-sm p-1">
            <DropdownMenu>
                <div className='w-full'>{title}</div>
                <DropdownMenuTrigger>
                    <DeleteOutlinedIcon fontSize="small" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => handleDeleteEvent(idEvent)}>Delete</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );

    /**
     * Handle selection of event type
     * @param {string} title - Event title
     * @param {string} bgColor - Event background color
     * @param {Date} start - Start date
     * @param {Date} end - End date
     */
    const handleSelectEventType = (title, bgColor, start, end) => {
        if (!viewOnly) {
            const trigger = eventComp(title, idNewEvent);
            if (selectedSlot) {
                setEvents([...events, { start, end, id: idNewEvent, title: trigger, bgColor }]);
            }
            setLengthEvents(lengthEvents + 1);
            setIdNewEvent(idNewEvent + 1);
            setDialogOpen(false);
            setSelectedSlot(null);
            setSelectedEvent(null);
            console.log("Event added:", { start, end, id: idNewEvent, title: trigger, bgColor });

        }
    };

    /**
     * Handle selection of an event
     * @param {Object} e - Event object
     */
    const handleSelectEvent = (e) => {
        if (!viewOnly) {
            console.log(events);
        }

    };

    /**
     * Handle deletion of an event
     * @param {number} eventIdToDelete - ID of the event to delete
     */
    const handleDeleteEvent = (eventIdToDelete) => {
        if (eventIdToDelete) {
            setEvents((prevEvents) => prevEvents.filter(event => event.id !== eventIdToDelete));
        }
        setLengthEvents(lengthEvents - 1);
    };

    /**
     * Customize event properties
     * @param {Object} event - Event object
     * @returns {Object} - Object containing className and style
     */
    const eventPropGetter = (event) => {
        const className = event.bgColor || '';
        return { className, style: {} };
    };

    /**
     * Handle event resize
     * @param {Object} param - Contains event, start date, and end date
     */
    const handleEventResize = ({ event, start, end }) => {
        const nextEvents = events.map(existingEvent => (existingEvent === event ? { ...existingEvent, start, end } : existingEvent));
        setEvents(nextEvents);
    };

    /**
     * Handle event drop
     * @param {Object} param - Contains event, start date, and end date
     */
    const handleEventDrop = ({ event, start, end }) => {
        if (!viewOnly) {

        const nextEvents = events.map(existingEvent => (existingEvent === event ? { ...existingEvent, start, end } : existingEvent));
        setEvents(nextEvents);
        }
    };

    /**
     * Handle setting context menu open
     * @param {Object} e - Event object
     */
    const handleSetContextMenuOpen = (e) => {
        setSelectedEvent(null);
    };

    /**
     * Send data (currently logs the events)
     */
    const sendData = () => {
        console.log("sendData", events);
    };

    useEffect(() => {
        setDialogOpen(false);
        setSelectedSlot(null);
        setSelectedEvent(null);
        console.log("useeffect events change : ", events);
    }, [events]);



    /**
     * Custom toolbar component for the calendar
     * @param {Object} toolbar - Toolbar object
     * @returns {JSX.Element} - Toolbar component
     */
    const CustomToolbar = (toolbar) => {
        const goToBack = () => toolbar.onNavigate('PREV');
        const goToNext = () => toolbar.onNavigate('NEXT');

        return (
            <div className="w-full flex flex-row items-center ">
                {/* <span className="flex flex-row">
                    <Button onClick={goToBack} sizePadding='firstDegree' style='ghost' showText={false} showLeftIcon leftIcon={<ArrowBackIcon />} className="rounded-sm" />
                    <Button onClick={goToNext} sizePadding='firstDegree' style='ghost' showText={false} showLeftIcon leftIcon={<ArrowForwardIcon />} className="rounded-sm" />
                </span> */}
                {/* <div className="w-full text-center">
                    <Label size="body" fontWeight="bold">{toolbar.label}</Label>
                </div> */}
            </div>
        );
    };

    const handleMonthChange = (e) => {
        const newMonth = moment(e.target.value, 'YYYY-MM').startOf('month').toDate();
        setSelectedMonth(moment(newMonth).format('YYYY-MM'));
    };





    return (
        <div className={viewOnly? `p-0`: `p-4`}>
            {!viewOnly && <div className="flex space-x-4 mb-4">

                <input
                    value={selectedMonth}
                    type="month"
                    onChange={(e) => handleMonthChange(e)}
                    className="col-span-3 p-2 border rounded-md text-body w-full"
                />
                <Select value={selectedClient} onValueChange={setSelectedClient}>
                    <SelectTrigger className={`col-span-3 p-2 border rounded-md text-body bg-white w-full`}>
                        <SelectValue placeholder={<Label message="global.actions.select" />} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="Client1">Client 1</SelectItem>
                        <SelectItem value="Client2">Client 2</SelectItem>
                        <SelectItem value="Client3">Client 3</SelectItem>
                    </SelectContent>
                </Select>
            </div>}
            <DragAndDropCalendar
                key={events.length} // Force re-render on events change
                selectable={viewOnly ? false : true}
                resizable={viewOnly ? false : true}
                localizer={localizer}
                events={events}
                defaultView="month"
                views={['month']}
                draggableAccessor={viewOnly ? true : false}
                date={moment(selectedMonth, 'YYYY-MM').startOf('month').toDate()}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={handleSelectSlot}
                eventPropGetter={eventPropGetter}
                onEventDrop={handleEventDrop}
                onEventResize={handleEventResize}
                onDoubleClickEvent={(e) => handleSetContextMenuOpen(e)}
                onSelectEvent={handleSelectEvent}
                onDropFromOutside={handleSelectSlot}
                selected={selectedEvent}
                components={{ toolbar: CustomToolbar }}
                className="gap-2 h-[80%] min-h-[600px]"
            />
            {/* <EventDialog
                open={dialogOpen}
                setDialogOpen={setDialogOpen}
                onSelect={handleSelectEventType}
                selectedSlot={selectedSlot}
            /> */}
            {needAbsolutePopUp ?
                <EventSettings
                    open={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    onSelect={handleSelectEventType}
                    selectedSlot={selectedSlot}
                />
                :
                <EventDialog
                    open={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    onSelect={handleSelectEventType}
                    selectedSlot={selectedSlot}
                />
            }

            {/* <Button onClick={sendData} text="global.actions.save"></Button> */}
        </div>
    );
};

export default TimesheetCalendar;

