import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { getVariantItems } from './variantLogics';

export function useNavbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const variantItems = user ? getVariantItems(user) : [];

    const handleResize = () => {
        setShowMenu(window.innerWidth < 1280);
    };

    const handleClick = () => {
        if (window.innerWidth < 1280) {
            setShowMenu((prevShowMenu) => !prevShowMenu);
        }

    };

    const isSelected = (path) => window.location.pathname.startsWith(path);

    useEffect(() => {
        setShowMenu(window.innerWidth < 1280);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showProfile = (isWebVersion) => {
        (!isWebVersion) && handleClick();
        console.log("TODO show popup for profile ")
    };

    const showNotification = (isWebVersion) => {
        (!isWebVersion) && handleClick();
        console.log("TODO show popup for  notification")

    }

    const handleNavItemClick = (path) => {
        navigate(path);
        handleClick();
    };

    return { showMenu, handleClick, variantItems, isSelected, selectedItem, handleNavItemClick, showProfile, showNotification };
}
