import { Label } from "@/components/atoms/texts/label";
import React, { useState, useRef, useEffect } from "react";

import useConsultant from "../../../../organisms/navigation/MenuConsultant/useConsultant";
import useConsultantData from "../../../../organisms/navigation/MenuConsultant/useConsultantData";
import { useConsultantContext } from "@/context/ConsultantContext";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, } from "@/components/organisms/forms/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "@/components/molecules/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckIcon from "@mui/icons-material/Check";
import useAllUsers from '@/services/global-services/query/useAllUsers';

export default function NewCooptation({ changeData, showErrorMessage, global = false, }) {
    const { usersFormated: users } = useConsultant();
    // const { users } = useConsultantData();
    //   const { usersFormated } = useConsultant(users);
    const { activeUser } = useConsultantContext();
    const [usersX, setUsersX] = useState({ users });
    const [numberOfLines, setNumberOfLines] = useState(0);
    const [pourcent, setPourcent] = useState(0);

    const [nameSelected, setNameSelected] = useState();

    useEffect(() => {
        activeUser && setNameSelected([{ name: `${activeUser.name}`, rowIndex: -1 },]);
        if (!global && activeUser) {
            // Appeler changeData avec la valeur initiale du coopté
            changeData(-1, activeUser.uuid, true);
        }
    }, [activeUser])

    const initialized = useRef(false);

    useEffect(() => {
        if (users && users.length > 0 && activeUser) {
            const usersFiltered = users.filter(
                (user) => user.uuid != activeUser.uuid
            );
            setUsersX(usersFiltered);
            initialized.current = true;
        };
        if (users && users.length > 0 && !activeUser) {
            setUsersX(users);
            initialized.current = true;
        }
    }, [users, nameSelected]);

    const line = (index) => [
        <>
            <TableCell className="py-0.5 px-0">
                <Select onValueChange={(value) => handleNameChange(value, index)}>
                    <SelectTrigger className="h-full border-0 bg-medium">
                        <SelectValue
                            placeholder={<Label message="global.actions.select" />}
                        />
                    </SelectTrigger>
                    <SelectContent>
                        {usersX.map((user) => (
                            <SelectItem key={user.uuid} value={user.uuid}>
                                {user.trigram + " - " + user.firstname + " " + user.lastname}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </TableCell>
            <TableCell className="p-1 ">
                <Input
                    onChange={(e) => controlPourcent(e, index)}
                    id={numberOfLines}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="0"
                    className="bg-transparent rounded-sm border-0 text-center h-8 percentage-input"
                    min="0"
                    step="10"
                    max="100"
                />
            </TableCell>
            <TableCell className="p-1">
                <Input
                    onChange={(e) => handleDurationChange(e, index)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    id={numberOfLines}
                    placeholder="∞"
                    className="bg-transparent rounded-sm border-0 text-center h-8"
                    min="0"
                    step="6"
                    max="120"
                />
            </TableCell>
            <TableCell className="p-1">
                <Input
                    onChange={(e) => handleDateChange(e, index)}
                    type="date"

                    id={numberOfLines}
                    className="bg-transparent rounded-sm border-0 text-center h-8"

                />
            </TableCell>

        </>,
    ];

    const [components, setComponents] = useState([]);

    const addComponent = () => {
        const numberOfLines = components.length;
        const index = numberOfLines;
        setNumberOfLines(index);
        const newComponent = line(index);
        setComponents((prevComponents) => [...prevComponents, newComponent]);
    };

    const controlPourcent = (e, index) => {
        const percentageInputs = document.querySelectorAll(".percentage-input");
        let totalPercentage = 0;
        percentageInputs.forEach((input) => {
            const value = parseInt(input.value, 10) || 0;
            totalPercentage += value;
        });
        setPourcent(totalPercentage);
        changeData(index, { pourcent: e.target.value });
    };
    const handleNameChange = (value, index) => {
        changeData(index, { uuid: value });
    };

    const handleDurationChange = (e, index) => {
        changeData(index, { duration: e.target.value });
    };
    const handleDateChange = (e, index) => {
        changeData(index, { date: e.target.value });
    };
    //   console.log("condition $$$$$", activeUser && users.find(user => user.name.includes(activeUser.name))?.name);
    //   console.log(nameInUserFormated);
    if (users) {
        // const nameInUserFormated = (activeUser && users && users.find(user => user.uuid.includes(activeUser.uuid))?.firstname);

        return (

            <div className='flex flex-col gap-secondDegree p-secondDegree'>
                <div className="flex flex-col space-y-4">
                    <Label message="consultants.dashboard.newCooptation.newConsultant" fontWeight="bold" />

                    <Label message="consultants.dashboard.newCooptation.newConsultantName" className="" />
                    <Select defaultValue={!global && activeUser.uuid} onValueChange={(value) => changeData(-1, value, true)}  >
                        <SelectTrigger>
                            <SelectValue placeholder={<Label message="global.actions.select" />} />
                        </SelectTrigger>

                        <SelectContent>
                            <>
                                {global
                                    ? users && users.map((user) => (
                                        <SelectItem value={user.uuid}>{user.trigram + " " + user.firstname + " " + user.lastname}</SelectItem>
                                    ))
                                    : activeUser &&
                                    <SelectItem value={activeUser.uuid}>{activeUser.trigram + " " + activeUser.firstname + " " + activeUser.lastname}</SelectItem>

                                }
                            </>
                        </SelectContent>
                    </Select>

                    <Label
                        message="consultants.dashboard.newCooptation.cooptedBy"
                        fontWeight="bold"
                    />
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="bg-transparent w-[25%] h-auto py-firstDegree">
                                    <Label message="consultants.dashboard.newCooptation.table.name" />{" "}
                                    *
                                </TableHead>
                                <TableHead className="">
                                    <Label message="consultants.dashboard.newCooptation.table.ownership" />{" "}
                                    *
                                </TableHead>
                                <TableHead className="">
                                    <Label message="consultants.dashboard.newCooptation.table.duration" />
                                </TableHead>
                                <TableHead className="">
                                    <Label message="consultants.dashboard.newCooptation.table.date" />
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {components.map((component, index) => (
                                <TableRow key={index}>{component}</TableRow>
                            ))}
                            <TableRow className="">
                                <Button
                                    style="dressedGhost"
                                    className="w-full text-primary "
                                    onClick={() => addComponent()}
                                    showText={false}
                                    showRightIcon={true}
                                    rightIcon={<AddIcon></AddIcon>}
                                />
                                {/* <TableCell /> */}
                                <TableCell
                                    className={`rounded-b-sm border-2  bg-medium text-center py-betweenText space-x-2 h-full ${pourcent === 100 ? "border-success" : "border-error"
                                        }`}
                                >
                                    <span>{pourcent}%</span>
                                    {pourcent === 100 ? (
                                        <CheckIcon color="success" />
                                    ) : (
                                        <ErrorOutlineIcon color="error" />
                                    )}
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                {/* Error messages based on `showErrorMessage` */}
                <Label
                    message="error.mulitpleSimilarCoopting"
                    className={` text-error ${showErrorMessage == "mulitpleSimilarCoopting" ? "flex" : "hidden"
                        }`}
                />
                <Label
                    message="error.totalOwnership"
                    className={`text-error ${showErrorMessage == "totalOwnership" ? "flex" : "hidden"
                        }`}
                />
                <Label
                    message="error.missingRequired"
                    className={`text-error ${showErrorMessage == "missingRequired" ? "flex" : "hidden"
                        }`}
                />
            </div>
        )
    } else { return (<></>) };
}
