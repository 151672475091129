
const data = [
    {
        uuid: '19b17af5-ec7f-46b1-ba44-dd3469f2f90d',
        year: 2024,
        month: 7,
        status: 'DRAFT',
        daysWorked: 15,
        daysIllness: 2,
        daysVacation: 10,
        daysTraining: 4,
        clientName: 'Client A'
    },
    {
        uuid: '08a092d1-38b1-4add-8d14-cc551d374aec',
        year: 2024,
        month: 6,
        status: 'SUBMITTED',
        daysWorked: 18,
        daysIllness: 1,
        daysVacation: 7,
        daysTraining: 4,
        clientName: 'Client B'
    },
    {
        uuid: 'dbe65a3e-d655-452c-83ac-221d4d7ee4bc',
        year: 2024,
        month: 5,
        status: 'APPROVED',
        daysWorked: 20,
        daysIllness: 1,
        daysVacation: 7,
        daysTraining: 3,
        clientName: 'Client C'
    },
    {
        uuid: 'dbe65a3e-d655-452c-83ac-221d4d7ee4bh',
        year: 2024,
        month: 4,
        status: 'APPROVED',
        daysWorked: 19,
        daysIllness: 0,
        daysVacation: 7,
        daysTraining: 4,
        clientName: 'Client D'
    },
    {
        uuid: 'dbe65a3e-d655-452c-83ac-221d4d7ee4ju',
        year: 2024,
        month: 3,
        status: 'REJECTED',
        daysWorked: 18,
        daysIllness: 2,
        daysVacation: 7,
        daysTraining: 4,
        clientName: 'Client E'
    },
    {
        uuid: 'dbe65a3e-d655-452c-83ac-221d4d7ee4io',
        year: 2024,
        month: 2,
        status: 'APPROVED',
        daysWorked: 20,
        daysIllness: 1,
        daysVacation: 4,
        daysTraining: 4,
        clientName: 'Client F'
    }
];


const dataTimeEntries = {
    "timeEntries": [
        {
            "uuid": "dbe65a3e-d655-452c-83ac-221d4d7ee4bc",
            "timesheetUuid": "19b17af5-ec7f-46b1-ba44-dd3469f2f90d",
            "day": 1,
            "type": "WORK",
            "duration": "FULL_DAY"
        },
        {
            "uuid": "19b17af5-ec7f-46b1-ba44-dd3469f2f90d",
            "timesheetUuid": "19b17af5-ec7f-46b1-ba44-dd3469f2f90d",
            "day": 2,
            "type": "WORK",
            "duration": "HALF_DAY_AFTERNOON"
        },
        {
            "uuid": "08a092d1-38b1-4add-8d14-cc551d374aec",
            "timesheetUuid": "19b17af5-ec7f-46b1-ba44-dd3469f2f90d",
            "day": 2,
            "type": "VACATION",
            "duration": "HALF_DAY_MORNING"
        }
    ]
}


const getTimesheetData = async () => {
    try {
        console.log('getTimesheetData Data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching timesheet data:', error.message);
        throw error;
    }
};
const getDataTimeEntries = async (year, month, timesheetId) => {
    if (year && month && timesheetId) {
        try {
            console.log('getTimesheetData Data:', dataTimeEntries.timeEntries);
            return dataTimeEntries.timeEntries;
        } catch (error) {
            console.error('Error fetching timesheet data:', error.message);
            throw error;
        }
    }else{
        console.error('year or month not defined:', year,month, timesheetId);
    }
};

export { getTimesheetData, getDataTimeEntries };
