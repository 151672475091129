import React from 'react'
import { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/organisms/forms/select";
import { Label } from '@/components/atoms/texts/label';
export default function UniqueSelect2({
    dataToSelect,
    defaultValue,
  filterDataFunction,
placeholderSelect,

  className,
  labelClassName,
  isSearchable = false,
  showLabel = true,

  ...props
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const placeHolder= placeholderSelect ? placeholderSelect : "global.actions.select"
    const handleSelectChange = (value) => {
        setSelectedValue(value)
        filterDataFunction(value)
        
    }
  return (
    <div className={className}>
      {" "}
      <Select onValueChange={(value) => handleSelectChange(value)} value={selectedValue}>
        <SelectTrigger className="h-full border-0 bg-medium">
          <SelectValue
            className="text-body"
            placeholder={<Label message={placeholderSelect} />}
          />
        </SelectTrigger>
        <SelectContent>
          {dataToSelect&& dataToSelect.map((item, index) => (
            <SelectItem key={index} value={item}>
              {item}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
