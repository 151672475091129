// useConsultantData.js
import { useState, useEffect } from 'react';

import { effect } from '@preact/signals-react';
import { getTimesheetData, getDataTimeEntries } from '@/services/global-services/timesheetData';

const useTimesheetData = () => {
    const [timesheets, setTimesheets] = useState(null);
    const [error, setError] = useState(null);
    effect(() => {
        // console.log('la valeur du signal change');
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                const tempData = await getTimesheetData();
                setTimesheets(tempData);
            } catch (error) {
                setError(error);
                //errorSignal.value=error
                console.error("Erreur récupération des données", error);
            }
        };

        fetchData();
    }, []);

    return { timesheets, error };
};

const useTimeEntriesData = (year,month, timesheetId) => {
    const [timeEntries, setTimeEntries] = useState(null);
    const [error, setError] = useState(null);
    effect(() => {
         console.log('useTimeEntriesData');
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                const tempData = await getDataTimeEntries(year,month, timesheetId);
                setTimeEntries(tempData);
            } catch (error) {
                setError(error);
                //errorSignal.value=error
                console.error("Erreur récupération des données", error);
            }
        };

        fetchData();
    }, []);

    return { timeEntries, error };
};

export {useTimesheetData, useTimeEntriesData};

