

import { useState, useEffect } from "react";
import useUser from "@/services/admin-services/query/useUser";
import useAllConsultantProfiles from "@/services/admin-services/query/useAllConsultantProfiles";
import useAllRetributionModels from "@/services/admin-services/query/useAllRetributionModels";

const useInformationData = (id) => {
    const [informationData, setInformationData] = useState(null);
    const [error, setError] = useState(null);

    const { getUser, user, loading: userLoading, error: userError } = useUser(id);
    const { consultantProfiles, loading: profilesLoading, error: profilesError } = useAllConsultantProfiles();
    const { retributionModels, loading: modelsLoading, error: modelsError } = useAllRetributionModels();

    useEffect(() => {
        if (user && consultantProfiles && retributionModels) {
            setInformationData({
                ...user,
                allConsultantProfiles: consultantProfiles,
                allRetributionsModels: retributionModels,
            });
        }


        setError({
            userError,
            profilesError,
            modelsError,
        });

    }, [user, consultantProfiles, retributionModels]);
    
    useEffect(() => {
        const fetchData = async () => {
            const { data, error } = await getUser(id);
            if (data) {
                setInformationData(data);
            } else {
                console.error("Error fetching user:", error);
                setError({ errorsBackEnd: error });
            }
        };

        fetchData();
    }, [id]);

    return { informationData, error, userError, profilesError, modelsError, userLoading, profilesLoading, modelsLoading };
};

export default useInformationData;
