import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { useCallback, useEffect, useMemo, useState } from "react";
import ControlsDownload from "@/components/molecules/controlsDownload";
import ReadingStatus from "./readingStatus";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import getMonthName from "@/utils/getMonthName";
import ShowTimesheet from "./ShowTimesheet";
import DaysSynthesis from "./DaysSynthesis";
import TimesheetCalendar from "./TimesheetCalendar";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";
import DragAndDropZone from "@/components/organisms/forms/DragAndDropZone";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { Toaster } from "@/components/organisms/feedback/toaster";
import NewTimesheetPanel from "./NewTimesheetPanel";
// "amountTax": { "5": "7.36", "10": "4.53", "12": "7.68" },
export default function Timesheet({ data, dataOwner }) {

    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(false);
    const [fileError, setFileError] = useState();

    const [totalDays, setTotalDays] = useState(0);

    const [loading, setLoading] = useState(true);
    const { toast } = useToast();

    useEffect(() => {
        if (data) {
            const total = data.daysWorked + data.daysIllness + data.daysVacation + data.daysTraining;
            setTotalDays(total);
        }
    }, [data])

    const handleSendFile = () => {
        console.log("file : ", file);
        setFileError();
        setFile()
        toast({
            variant: "success",
            title: "success.sendFile",
            duration: 1500,
        });
    }

    return (
        <div className={`bg-white w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom  rounded-sm justify-start items-center flex flex-col gap-secondDegree`}>
            <div className="flex justify-start items-center  gap-secondDegree w-full">
                <Button style="ghost" sizePadding="small" showLeftIcon leftIcon={open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />} showText={false} onClick={() => setOpen(!open)} />
                <ReadingStatus type={data.status} data="category" />
                <div className="flex gap-betweenText">
                    <Label message="consultants.timesheet.title" />
                    <Label>{getMonthName(data.month)}</Label>
                    <Label>{data.year}</Label>
                </div>

                <div className="grow"></div>
                {(data.status == "DRAFT" || data.status == "REJECTED") &&
                    <Dialog >
                        <DialogTrigger>
                            <Button style="pill" sizePadding="pill" text="consultants.timesheet.importFile" />
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle><Label message="consultants.timesheet.importFile" size="h4" /></DialogTitle>
                                <DialogDescription className="flex flex-col gap-4 py-4">
                                    <DragAndDropZone file={file} setFile={setFile} fileError={fileError} className=" border-primary" />
                                </DialogDescription>
                            </DialogHeader>
                            <DialogFooter className="sm:justify-start">
                                <DialogClose asChild>
                                    <Button type="button" style="ghost" text='global.actions.cancel' onClick={() => { setFile(), setFileError() }} />
                                </DialogClose>
                                <DialogClose className="w-full">
                                    <Button style="fill" text='global.actions.save' className="w-full" onClick={() => { handleSendFile() }} />
                                </DialogClose>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                }
                {dataOwner && (data.status == "DRAFT" || data.status == "REJECTED") &&
                    <NewTimesheetPanel global={false} isEditing year={data.year} month={data.month} timesheetId={data.uuid}/>
                }
                <Label>{data.clientName}</Label>
                <ControlsDownload dataOwner={dataOwner} onClickNewTab={() => console.log("onClickNewTab")} onClickDownload={() => console.log("onClickDownload")} onClickDelete={() => console.log("onClickDelete")} />
            </div>
            {open && <div className=" w-full flex gap-secondDegree">
                <div className="w-1/4 flex flex-col gap-firstDegree">
                    {/* Synthesis */}
                    <DaysSynthesis data={data.daysWorked} message="daysWorked" />
                    <DaysSynthesis data={data.daysIllness} message="daysIllness" />
                    <DaysSynthesis data={data.daysVacation} message="daysVacation" />
                    <DaysSynthesis data={data.daysTraining} message="daysTraining" />
                </div>
                <div className="w-3/4">
                    <ShowTimesheet year={data.year} month={data.month} timesheetId={data.uuid} totalDays={totalDays}></ShowTimesheet>
                </div>
            </div>}

        </div>
    )
    // };
}