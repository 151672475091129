// import { useAuth } from '@/context/AuthContext';
// import { useState } from 'react';
// import errorToText from '@/utils/errorToText';

// const useUpdateUser = () => {

//     const [response, setResponse] = useState(null);
//     const { user: authUser } = useAuth();

//     const setRetribution = async (uuid, retributionModelUuid) => {
//         if (!authUser.strongToken) {
//             setError('No strong token available');
//             return { data: null, error: 'No strong token available' };
//         }

//         try {
//             const response = await fetch(window.ENV.BACKEND_URL+'/api/graphql', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${authUser.strongToken}`
//                 },
//                 body: JSON.stringify({
//                     query: `
//                     mutation updateRetribution {
//                         updateUser(updatedUser: {
//                             uuid: "${uuid}", 
//                             retributionModelUuid: "${retributionModelUuid}"
//                           }){
//                             retributionModel {
//                                 uuid
//                                 upToTenConsultants
//                                 upToFiveConsultants
//                                 moreThanTenConsultants
//                               }
//                           }
//                         }
//                     `
//                 })
//             });


//             if (!response.ok) {
//                 const errMessage = errorToText(response);
//                 throw new Error(errMessage);
//             }

//             const data = await response.json();
//             if (data.errors) {
//                 const errMessage = data.errors[0].message;
//                 throw new Error(errMessage);
//             }

//             setResponse(data.data.updateUser);

//             return { data: data.data.updateUser, error: null };
//         } catch (err) {
//             return { data: null, error: err.message };
//         }
//     };

//     return { setRetribution, uuidResponnse: response };
// };

// export default useUpdateUser;


// // updateUser(updatedUser: {uuid: ""}) {
// //     language
// //   }


import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import errorToText from '@/utils/errorToText';

// Define the GraphQL mutations
const UPDATE_USER_RETRIBUTION = gql`
  mutation updateRetribution($uuid: ID!, $retributionModelUuid: ID!) {
    updateUser(updatedUser: {uuid: $uuid, retributionModelUuid: $retributionModelUuid}) {
      retributionModel {
        uuid
        upToTenConsultants
        upToFiveConsultants
        moreThanTenConsultants
      }
    }
  }
`;

const UPDATE_USER_LANGUAGE = gql`
  mutation updateLanguage($uuid: ID!, $language: InterfaceLanguage!) {
    updateUser(updatedUser: {uuid: $uuid, language: $language}) {
      language
    }
  }
`;

const useUpdateUser = () => {
    const { user: authUser } = useAuth();
    const [response, setResponse] = useState(null);

    const [updateRetribution] = useMutation(UPDATE_USER_RETRIBUTION, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(errorToText(error));
        },
    });

    const [updateLanguage] = useMutation(UPDATE_USER_LANGUAGE, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        onError: (error) => {
            console.error(errorToText(error));
        },
    });

    const setRetribution = async (uuid, retributionModelUuid) => {
        try {
            const { data } = await updateRetribution({
                variables: { uuid, retributionModelUuid },
            });
            setResponse(data.updateUser);
            return { data: data.updateUser, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    const setLanguage = async (language) => {
        const uuid = authUser.uuid;
        try {
            const { data } = await updateLanguage({
                variables: { uuid, language },
            });
            setResponse(data.updateUser);
            return { data: data.updateUser, error: null };
        } catch (err) {
            return { data: null, error: err.message };
        }
    };

    return { setRetribution, setLanguage, uuidResponse: response };
};

export default useUpdateUser;
