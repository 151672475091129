import { useState } from "react";
import { useAuth } from "@/context/AuthContext";

export const useGetLeasingInvoice = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const getLeasingInvoice = async (leasingInvoiceFileUuid) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(window.ENV.BACKEND_URL+`/api/secured/users/leasing/leasingInvoiceFile/${leasingInvoiceFileUuid}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user.strongToken}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to retrieve leasing invoice");
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            console.log("blob file leasing ", blob, url)

            setData({ url, type: blob.type });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { getLeasingInvoice, loading, error, data };
};
